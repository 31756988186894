<script setup>
import Monthly from '@/pages/statistics/components/Monthly.vue';
import Genders from '@/pages/statistics/components/Genders.vue';
import BloodGroups from '@/pages/statistics/components/BloodGroups.vue';
import Diagnostics from '@/pages/statistics/components/Diagnostics.vue';
import Techniques from '@/pages/statistics/components/Techniques.vue';
import Balance from '@/pages/statistics/components/Balance.vue';
import Prescribers from '@/pages/statistics/components/Prescribers.vue';
import Anesthesiologists from '@/pages/statistics/components/Anesthesiologists.vue';
import PatientStatistics from '@/pages/statistics/components/Patient.vue';
import { useStatistics } from "@/composables/Tenant/useStatistics";
import { onMounted, computed, ref } from 'vue';

definePage({
  meta: {
    isRoute: true,
    navActiveLink: "statistics",
    ownerOnly: true,
    userTypes: ['doctor', 'user'],
    feature: 'reporting_tools',
  },
});

const { statisticsData, fetching, fetchAllStatistics, updateYear } = useStatistics();

const years = ref({
  balance: null,
  genders: null,
  bloodGroups: null,
  consultations: null,
  interventions: null,
  controls: null,
  diagnostics: null,
  techniques: null,
  prescribers: null,
  anesthesiologists: null,
  combinedMonthly: null,
});

onMounted(() => {
  fetchAllStatistics();
});

const availableYears = computed(() => {
  const allYears = new Set();
  Object.values(statisticsData).forEach(stat => {
    if (stat.availableYears) {
      stat.availableYears.forEach(year => allYears.add(year));
    }
  });
  return Array.from(allYears).sort((a, b) => b - a);
});

const handleYearChange = (statistic, newYear) => {
  years.value[statistic] = newYear;
  updateYear(newYear);
};
</script>

<template>
  <VRow id="apex-chart-wrapper" class="equal-height-cards">
    <PatientStatistics v-if="!fetching.patients" :stats="statisticsData.patients" />
    <VSkeletonLoader v-else min-height="150" loading-text="Loading" type="table-row-divider" class="w-100" />
    <VDivider class="ma-5" />

    <VCol cols="12" md="12">
      <VCard class="fill-height">
        <VCardItem class="d-flex flex-wrap justify-space-between gap-4">
          <VCardTitle>{{ $t('Revenu') }}</VCardTitle>
          <VCardSubtitle>{{ statisticsData.balance.totalAmount }} TND</VCardSubtitle>

          <template #append>
            <div class="date-picker-wrapper">
              <AppSelect v-if="!fetching.balance" v-model="years.balance" :items="statisticsData.balance.availableYears"
                prepend-icon="tabler-calendar" single-line variant="filled" :placeholder="$t('Year')"
                @update:model-value="(year) => handleYearChange('balance', year)" />
            </div>
          </template>
        </VCardItem>

        <VCardText>
          <div v-if="!fetching.balance" class="h-100">
            <Balance v-if="statisticsData.balance.availableYears.length" :data="statisticsData.balance" />
            <span v-else class="d-flex justify-center align-center h-100">
              {{ $t('NoData') }}
            </span>
          </div>
          <VSkeletonLoader v-else min-height="350" loading-text="Loading" type="card" class="fill-height" />
        </VCardText>
      </VCard>
    </VCol>

    <VCol cols="12" md="6">
      <VCard class="fill-height">
        <VCardItem class="d-flex flex-wrap justify-space-between gap-4">
          <VCardTitle>{{ $t('Genders') }}</VCardTitle>
          <template #append>
            <div class="date-picker-wrapper">
              <AppSelect v-if="!fetching.genders" v-model="years.genders" :items="statisticsData.genders.availableYears"
                prepend-icon="tabler-calendar" single-line variant="filled" :placeholder="$t('Year')"
                @update:model-value="(year) => handleYearChange('genders', year)" />
            </div>
          </template>
        </VCardItem>

        <VCardText>
          <div v-if="!fetching.genders" class="h-100">
            <Genders v-if="statisticsData.genders.availableYears.length" :data="statisticsData.genders" />
            <span v-else class="d-flex justify-center align-center h-100">
              {{ $t('NoData') }}
            </span>
          </div>
          <VSkeletonLoader v-else min-height="350" loading-text="Loading" type="card" class="fill-height" />
        </VCardText>
      </VCard>
    </VCol>

    <VCol cols="12" md="6">
      <VCard class="fill-height">
        <VCardItem class="d-flex flex-wrap justify-space-between gap-4">
          <VCardTitle>{{ $t('Blood Groups') }}</VCardTitle>
          <template #append>
            <div class="date-picker-wrapper">
              <AppSelect v-if="!fetching.bloodGroups" v-model="years.bloodGroups"
                :items="statisticsData.bloodGroups.availableYears" prepend-icon="tabler-calendar" single-line
                variant="filled" :placeholder="$t('Year')"
                @update:model-value="(year) => handleYearChange('bloodGroups', year)" />
            </div>
          </template>
        </VCardItem>
        <VCardText>
          <div v-if="!fetching.bloodGroups" class="h-100">
            <BloodGroups v-if="statisticsData.bloodGroups.availableYears.length" :data="statisticsData.bloodGroups" />
            <span v-else class="d-flex justify-center align-center h-100">
              {{ $t('NoData') }}
            </span>
          </div>
          <VSkeletonLoader v-else min-height="350" loading-text="Loading" type="card" class="fill-height" />
        </VCardText>
      </VCard>
    </VCol>

    <VCol cols="12">
      <VCard class="fill-height">
        <VCardItem class="d-flex flex-wrap justify-space-between gap-4">
          <VCardTitle>{{ $t('Monthly Visits') }}</VCardTitle>
          <template #append>
            <div class="date-picker-wrapper">
              <AppSelect v-if="!fetching.combinedMonthly" v-model="years.combinedMonthly"
                :items="statisticsData.combinedMonthly.availableYears" prepend-icon="tabler-calendar" single-line
                variant="filled" :placeholder="$t('Year')"
                @update:model-value="(year) => handleYearChange('combinedMonthly', year)" />
            </div>
          </template>
        </VCardItem>
        <VCardText>
          <div v-if="!fetching.combinedMonthly" class="h-100">
            <Monthly v-if="statisticsData.combinedMonthly.availableYears.length"
              :data="statisticsData.combinedMonthly" />
            <span v-else class="d-flex justify-center align-center h-100">
              {{ $t('NoData') }}
            </span>
          </div>
          <VSkeletonLoader v-else min-height="350" loading-text="Loading" type="card" class="fill-height" />
        </VCardText>
      </VCard>
    </VCol>

    <VCol cols="12" md="6">
      <VCard class="fill-height">
        <VCardItem class="d-flex flex-wrap justify-space-between gap-4">
          <VCardTitle>{{ $t('Diagnostics') }}</VCardTitle>
          <template #append>
            <div class="date-picker-wrapper">
              <AppSelect v-if="!fetching.diagnostics" v-model="years.diagnostics"
                :items="statisticsData.diagnostics.availableYears" prepend-icon="tabler-calendar" single-line
                variant="filled" :placeholder="$t('Year')"
                @update:model-value="(year) => handleYearChange('diagnostics', year)" />
            </div>
          </template>
        </VCardItem>
        <VCardText>
          <div v-if="!fetching.diagnostics" class="h-100">
            <Diagnostics v-if="statisticsData.diagnostics.availableYears.length" :data="statisticsData.diagnostics" />
            <span v-else class="d-flex justify-center align-center h-100">
              {{ $t('NoData') }}
            </span>
          </div>
          <VSkeletonLoader v-else min-height="350" loading-text="Loading" type="card" class="fill-height" />
        </VCardText>
      </VCard>
    </VCol>

    <VCol cols="12" md="6">
      <VCard class="fill-height">
        <VCardItem class="d-flex flex-wrap justify-space-between gap-4">
          <VCardTitle>{{ $t('Techniques') }}</VCardTitle>
          <template #append>
            <div class="date-picker-wrapper">
              <AppSelect v-if="!fetching.techniques" v-model="years.techniques"
                :items="statisticsData.techniques.availableYears" prepend-icon="tabler-calendar" single-line
                variant="filled" :placeholder="$t('Year')"
                @update:model-value="(year) => handleYearChange('techniques', year)" />
            </div>
          </template>
        </VCardItem>
        <VCardText>
          <div v-if="!fetching.techniques" class="h-100">
            <Techniques v-if="statisticsData.techniques.availableYears.length" :data="statisticsData.techniques" />
            <span v-else class="d-flex justify-center align-center h-100">
              {{ $t('NoData') }}
            </span>
          </div>
          <VSkeletonLoader v-else min-height="350" loading-text="Loading" type="card" class="fill-height" />
        </VCardText>
      </VCard>
    </VCol>

    <VCol cols="12" md="6">
      <VCard class="fill-height">
        <VCardItem class="d-flex flex-wrap justify-space-between gap-4">
          <VCardTitle>{{ $t('Prescribers') }}</VCardTitle>
          <template #append>
            <div class="date-picker-wrapper">
              <AppSelect v-if="!fetching.prescribers" v-model="years.prescribers"
                :items="statisticsData.prescribers.availableYears" prepend-icon="tabler-calendar" single-line
                variant="filled" :placeholder="$t('Year')"
                @update:model-value="(year) => handleYearChange('prescribers', year)" />
            </div>
          </template>
        </VCardItem>
        <VCardText>
          <div v-if="!fetching.prescribers" class="h-100">
            <Prescribers v-if="statisticsData.prescribers.availableYears.length" :data="statisticsData.prescribers" />
            <span v-else class="d-flex justify-center align-center h-100">
              {{ $t('NoData') }}
            </span>
          </div>
          <VSkeletonLoader v-else min-height="350" loading-text="Loading" type="card" class="fill-height" />
        </VCardText>
      </VCard>
    </VCol>

    <VCol cols="12" md="6">
      <VCard class="fill-height">
        <VCardItem class="d-flex flex-wrap justify-space-between gap-4">
          <VCardTitle>{{ $t('Anesthesiologists') }}</VCardTitle>
          <template #append>
            <div class="date-picker-wrapper">
              <AppSelect v-if="!fetching.anesthesiologists" v-model="years.anesthesiologists"
                :items="statisticsData.anesthesiologists.availableYears" prepend-icon="tabler-calendar" single-line
                variant="filled" :placeholder="$t('Year')"
                @update:model-value="(year) => handleYearChange('anesthesiologists', year)" />
            </div>
          </template>
        </VCardItem>
        <VCardText>
          <div v-if="!fetching.anesthesiologists" class="h-100">
            <Anesthesiologists v-if="statisticsData.anesthesiologists.availableYears.length"
              :data="statisticsData.anesthesiologists" />
            <span v-else class="d-flex justify-center align-center h-100">
              {{ $t('NoData') }}
            </span>
          </div>
          <VSkeletonLoader v-else min-height="350" loading-text="Loading" type="card" class="fill-height" />
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core-scss/template/libs/apex-chart.scss";

.date-picker-wrapper {
  inline-size: 10.5rem;
}

#apex-chart-wrapper {
  .v-card-item__append {
    padding-inline-start: 0;
  }

  .v-col {
    display: flex;
    flex-direction: column;
  }

  .v-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  .fill-height {
    height: 100%;
  }
}
</style>