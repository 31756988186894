import { defineStore, storeToRefs } from "pinia";
import { ref, computed, watch } from "vue";
import { useTheme } from "vuetify";
import { usePreferredColorScheme } from "@vueuse/core";
import { themeConfig } from "@themeConfig";
import { useLayoutConfigStore } from "@layouts/stores/config";
import { Theme } from "@core/enums";
import { useUserStore } from "./useUserStore";
import { staticPrimaryColor } from "@/plugins/vuetify/theme";
import { AppContentLayoutNav } from "@layouts/enums";
import { usePermissionsStore } from "./usePermissionsStore";
import { useAuthentication } from "@/@core/composable/useAuthentication";
import axios from "axios";
import { useCookie } from "@/@core/composable/useCookie";
import { namespaceConfig } from "@layouts/stores/config";

export const useThemeStore = defineStore("theme", () => {
    const vuetifyTheme = useTheme();
    const userPreferredColorScheme = usePreferredColorScheme();
    const layoutConfigStore = useLayoutConfigStore();

    const theme = ref(themeConfig.app.theme);
    const skin = ref(themeConfig.app.skin);
    const isVerticalNavSemiDark = ref(
        themeConfig.verticalNav.isVerticalNavSemiDark
    );
    const primaryColor = ref(staticPrimaryColor);
    const isReady = ref(false);
    const refreshInterval = ref(30 * 60 * 1000);
    const lastRefreshTime = ref(null);

    const currentTheme = computed(() =>
        theme.value === Theme.System
            ? userPreferredColorScheme.value
            : theme.value
    );

    const {
        isLessThanOverlayNavBreakpoint,
        appContentWidth,
        navbarType,
        isNavbarBlurEnabled,
        appContentLayoutNav,
        footerType,
        isAppRTL,
    } = storeToRefs(useLayoutConfigStore());

    watch(
        currentTheme,
        (newTheme) => {
            vuetifyTheme.global.name.value = newTheme;
        },
        { immediate: true }
    );

    

    function setTheme(newTheme) {
        theme.value = newTheme;
    }

    function updateThemeColors(color) {
        primaryColor.value = color || staticPrimaryColor;
        vuetifyTheme.themes.value.light.colors.primary = primaryColor.value;
        vuetifyTheme.themes.value.dark.colors.primary = primaryColor.value;
    }

    function applyThemeSettings(settings) {
        if (settings) {
            setTheme(settings.theme || theme.value);
            updateThemeColors(settings.primaryColor);
            isVerticalNavSemiDark.value = Boolean(
                settings.isVerticalNavSemiDark
            );
            skin.value = settings.skin || skin.value;
            appContentLayoutNav.value =
                settings.layout || layoutConfigStore.appContentLayoutNav;
            appContentWidth.value =
                settings.appContentWidth || appContentWidth.value;
        }
    }

    function saveThemeToCookie(settings) {
        const themeCookie = useCookie(namespaceConfig("Theme"));
        themeCookie.value = JSON.stringify({
            theme: settings.theme || theme.value,
            skin: settings.skin || skin.value,
            isVerticalNavSemiDark: Boolean(settings.isVerticalNavSemiDark),
            primaryColor: settings.primaryColor || primaryColor.value,
            appContentLayoutNav: settings.layout || appContentLayoutNav.value,
            appContentWidth: settings.appContentWidth || appContentWidth.value,
            isAppRTL: settings.isAppRTL || isAppRTL.value,
            isNavbarBlurEnabled:
                settings.isNavbarBlurEnabled || isNavbarBlurEnabled.value,
        });
    }

    function initializeTheme() {
        const themeCookie = useCookie(namespaceConfig("Theme"));
        const permissionStore = usePermissionsStore();

        if (permissionStore.isPatient) {
            layoutConfigStore.appContentLayoutNav =
                AppContentLayoutNav.Horizontal;
        } else {
            layoutConfigStore.appContentLayoutNav =
                AppContentLayoutNav.Vertical;
        }

        if (themeCookie.value) {
            let savedTheme;
            if (typeof themeCookie.value === "string") {
                try {
                    savedTheme = JSON.parse(themeCookie.value);
                } catch (error) {
                    console.error("Error parsing theme cookie:", error);
                    return;
                }
            } else {
                savedTheme = themeCookie.value;
            }
            applyThemeSettings(savedTheme);
        }
    }

    function initializeSocket() {
        const userStore = useUserStore();
        // window.Echo.private(`app.${userStore.tenantId}`).listen(
        //     "AppUpdated",
        //     (e) => {
        //         fetch();
        //     }
        // );
    }

    function resetSocket() {
        const userStore = useUserStore();
        const channelName = `app.${userStore.tenantId}`;
        // window.Echo.private(channelName).stopListening("AppUpdated");
        // window.Echo.leave(channelName);
    }

    async function fetch() {
        try {
            const { isAuthenticated } = useAuthentication();

            if (isAuthenticated) {
                const userStore = useUserStore();
                if (userStore.type === "doctor" || userStore.type === "user") {
                    const response = await axios.get("/api/theme");
                    applyThemeSettings(response.data);
                    saveThemeToCookie(response.data);
                }
            }

            lastRefreshTime.value = Date.now();
        } catch (error) {
            console.error("Error fetching theme settings:", error);
            throw error;
        }
    }

    async function initialize() {
        try {
            await fetch();
            initializeTheme();
            initializeSocket();
            isReady.value = true;
        } catch (error) {
            console.error("Error initializing theme:", error);
            isReady.value = false;
            throw error;
        }
    }

    function reset() {
        theme.value = themeConfig.app.theme;
        skin.value = themeConfig.app.skin;
        isVerticalNavSemiDark.value =
            themeConfig.verticalNav.isVerticalNavSemiDark;
        primaryColor.value = staticPrimaryColor;
        updateThemeColors();
        isReady.value = false;
        lastRefreshTime.value = null;
        useCookie(namespaceConfig("Theme")).value = null;
        resetSocket();
    }

    return {
        theme,
        skin,
        isVerticalNavSemiDark,
        primaryColor,
        isReady,
        currentTheme,
        refreshInterval,
        lastRefreshTime,
        setTheme,
        updateThemeColors,
        fetch,
        initialize,
        initializeTheme,
        initializeSocket,
        reset,
        isLessThanOverlayNavBreakpoint,
        appContentWidth,
        navbarType,
        isNavbarBlurEnabled,
        appContentLayoutNav,
        footerType,
        isAppRTL,
    };
});