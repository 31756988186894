import { breakpointsVuetify } from '@vueuse/core';
import { VIcon } from 'vuetify/components/VIcon';
import { defineThemeConfig } from '@core';
import { Skins } from '@core/enums';
import logo from "@images/logo.svg?raw";
import logomini from "@images/logomini.svg?raw";
import { h } from 'vue';
import { AppContentLayoutNav, ContentWidth, FooterType, NavbarType } from '@layouts/enums';

export const { themeConfig, layoutConfig } = defineThemeConfig({
    app: {
        title: '',
        logo: h('div', { innerHTML: logo, style: 'line-height:0; color: rgb(var(--v-global-theme-primary))' }),
        logomini: h('div', { innerHTML: logomini, style: 'line-height:0; color: rgb(var(--v-global-theme-primary))' }),
        contentWidth: ContentWidth.Boxed,
        contentLayoutNav: AppContentLayoutNav.Vertical,
        overlayNavFromBreakpoint: breakpointsVuetify.md + 16,
        i18n: {
            enable: true,
            defaultLocale: 'fr',
            langConfig: [{
                    label: 'English',
                    i18nLang: 'en',
                    isRTL: false,
                },
                {
                    label: 'French',
                    i18nLang: 'fr',
                    isRTL: false,
                },
                {
                    label: 'Arabic',
                    i18nLang: 'ar',
                    isRTL: true,
                },
            ],
        },
        theme: 'light',
        skin: Skins.Default,
        iconRenderer: VIcon,
    },
    navbar: {
        type: NavbarType.Sticky,
        navbarBlur: true,
    },
    footer: { type: FooterType.Static },
    verticalNav: {
        isVerticalNavCollapsed: false,
        defaultNavItemIconProps: { icon: 'tabler-circle', size: 10 },
        isVerticalNavSemiDark: false,
    },
    horizontalNav: {
        type: 'sticky',
        transition: 'slide-y-reverse-transition',
    },

    /*
      // ℹ️  In below Icons section, you can specify icon for each component. Also you can use other props of v-icon component like `color` and `size` for each icon.
      // Such as: chevronDown: { icon: 'tabler-chevron-down', color:'primary', size: '24' },
      */
    icons: {
        chevronDown: { icon: 'tabler-chevron-down' },
        chevronRight: { icon: 'tabler-chevron-right', size: 18 },
        close: { icon: 'tabler-x' },
        verticalNavPinned: { icon: 'tabler-medical-cross' },
        verticalNavUnPinned: { icon: 'tabler-medical-cross-off' },
        sectionTitlePlaceholder: { icon: 'tabler-separator' },
    },
})