<script setup>
import Feed from './components/VWindows/Feed.vue';
import Network from './components/VWindows/Network.vue';
import Chat from './components/VWindows/Chat.vue';
import { useNavigationStore } from "@/stores/useNavigationStore";
const navigationStore = useNavigationStore();

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "platform",
        // ownerOnly: true,
        userTypes: ['doctor'],
        // feature: 'community_networking',
    },
});
</script>

<template>
     <div style="height: 70px;">

     </div>
    <VWindow v-model="navigationStore.platformTab">
        <VWindowItem value="tab-feed">
            <Feed />
        </VWindowItem>
        <VWindowItem value="tab-network">
            <Network/>
        </VWindowItem>
        <VWindowItem value="tab-messages">
            <Chat/>
        </VWindowItem>
    </VWindow>
</template>