import { defineStore } from "pinia";
import { usePermissionsStore } from "@/stores/usePermissionsStore";
import { useAppStore } from "@/stores/useAppStore";
import { usePlanStore } from "@/stores/usePlanStore";
import { useNavigationStore } from "@/stores/useNavigationStore";
import { useUserStore } from "@/stores/useUserStore";
import { useNotificationsStore } from "@/stores/useNotificationsStore";
import { useThemeStore } from "@/stores/useThemeStore";
import { useShortcutsStore } from "./useShortcutsStore";
import { useAuthentication } from "@/@core/composable/useAuthentication";
import { encryptedStorage } from "../@core/composable/encryptedStorage";

export const useInitializationStore = defineStore("initialization", {
    state: () => ({
        isInitialized: false,
        error: null,
        nextRefreshTime: null,
        refreshTimeout: null,

        stores: {
            user: useUserStore(),
            theme: useThemeStore(),
            permissions: usePermissionsStore(),
            plan: usePlanStore(),
            app: useAppStore(),
            navigation: useNavigationStore(),
            shortcuts: useShortcutsStore(),
            notifications: useNotificationsStore(),
        },
    }),

    actions: {
        async initialize() {
            const { isAuthenticated } = useAuthentication();
            if (this.isInitialized || !isAuthenticated) return;

            try {
                await this.stores.user.initialize();
                await this.stores.permissions.initialize();

                const userType = this.stores.user.type;

                if (userType === "user" || userType === "doctor") {
                    await this.stores.plan.initialize();
                    await this.stores.app.initialize();
                    await this.stores.shortcuts.initialize();
                    await this.stores.notifications.initialize();
                } else {
                    this.stores.plan.isReady = true;
                    this.stores.app.isReady = true;
                    this.stores.shortcuts.isReady = true;
                    this.stores.notifications.isReady = true;
                }

                if (userType !== "patient") {
                    await this.stores.navigation.initialize();
                } else {
                    this.stores.navigation.isReady = true;
                }

                await this.stores.theme.initialize();

                this.isInitialized = true;
                this.error = null;
                this.startRefreshTimer();
            } catch (error) {
                console.error("Initialization failed:", error);
                this.logError(error);
                this.handleInitializationFailure();
            }
        },
        handleInitializationFailure() {
            this.reset();
            document.cookie.split(";").forEach(function (c) {
                document.cookie = c
                    .replace(/^ +/, "")
                    .replace(
                        /=.*/,
                        "=;expires=" + new Date().toUTCString() + ";path=/"
                    );
            });
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = "/";
        },

        startRefreshTimer() {
            this.scheduleNextRefresh();
        },

        stopRefreshTimer() {
            if (this.refreshTimeout) {
                clearTimeout(this.refreshTimeout);
                this.refreshTimeout = null;
            }
        },
        async fetchStores() {
            const { isAuthenticated } = useAuthentication();
            if (!isAuthenticated) {
                this.stopRefreshTimer();
                return;
            }

            const currentTime = Date.now();

            for (const [key, store] of Object.entries(this.stores)) {
                if (
                    store.refreshInterval &&
                    (!store.lastRefreshTime ||
                        currentTime >=
                            store.lastRefreshTime + store.refreshInterval)
                ) {
                    try {
                        if (typeof store.fetch === "function") {
                            await store.fetch();
                            store.lastRefreshTime = currentTime;
                        } else {
                            console.warn(
                                `Store ${key} does not have a fetch method`
                            );
                        }
                    } catch (error) {
                        console.error(`Failed to fetch ${key} store:`, error);
                        this.handleInitializationFailure();
                        return;
                    }
                }
            }

            this.scheduleNextRefresh();
        },
        scheduleNextRefresh() {
            const currentTime = Date.now();
            let earliestRefreshTime = Infinity;

            for (const store of Object.values(this.stores)) {
                if (store.refreshInterval) {
                    const nextRefreshTime =
                        (store.lastRefreshTime || currentTime) +
                        store.refreshInterval;
                    if (nextRefreshTime < earliestRefreshTime) {
                        earliestRefreshTime = nextRefreshTime;
                    }
                }
            }

            if (earliestRefreshTime !== Infinity) {
                const delay = Math.max(0, earliestRefreshTime - currentTime);
                this.stopRefreshTimer();
                this.refreshTimeout = setTimeout(
                    () => this.fetchStores(),
                    delay
                );
                this.nextRefreshTime = earliestRefreshTime;
            }
        },
        initializeSocket() {
            const { isAuthenticated } = useAuthentication();
            if (
                !isAuthenticated ||
                !["doctor", "user"].includes(this.stores.user.type)
            )
                return;

            Object.values(this.stores).forEach((store) => {
                if (typeof store.initializeSocket === "function") {
                    store.initializeSocket();
                }
            });
        },
        async reset() {
            // await this.resetSocket();

            Object.values(this.stores).forEach((store) => {
                if (typeof store.reset === "function") {
                    store.reset();
                }
            });

            this.stopRefreshTimer();
            this.$reset();
            localStorage.removeItem("initialization");
        },

        async resetSocket() {
            const { isAuthenticated } = useAuthentication();
            if (!isAuthenticated) return;

            Object.values(this.stores).forEach((store) => {
                if (typeof store.resetSocket === "function") {
                    store.resetSocket();
                }
            });
        },
        logError(error) {
            axios
                .post("/api/log-error", {
                    message: error.message,
                    url: window.location.href,
                    line: error.line,
                    column: error.column,
                    error: error.stack,
                    userAgent: navigator.userAgent,
                })
                .catch((err) => {
                    console.error("Failed to send error log:", err);
                });
        },
    },

    persist: {
        storage: encryptedStorage,
        paths: ["isInitialized", "nextRefreshTime"],
    },
});
