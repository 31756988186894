<script setup>
import { useDatabase } from "@/composables/Central/Admin/useDatabase";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import MessageDialog from "@/components/MessageDialog.vue";
import { useI18n } from 'vue-i18n';
import { ref, computed, watchEffect } from 'vue';
import { useRouter } from "vue-router";

definePage({
    meta: {
        isRoute: true,
        adminOnly: true,
        userTypes: ['admin'],
    },
});

const { t } = useI18n();
const router = useRouter();
const databases = ref([]);
const totalDatabases = ref(0);
const searchQuery = ref("");
const itemsPerPage = ref(10);
const page = ref(1);
const sortBy = ref([]);
const isLoading = ref(true);
const errorDialog = ref(false);
const errorMessage = ref('');
const expandedItems = ref([]);

const updateOptions = (options) => {
    page.value = options.page;
    itemsPerPage.value = options.itemsPerPage;
    sortBy.value = options.sortBy;
    refreshDatabases();
};

const { fetchDatabases, destroyDatabase, destroyTable } = useDatabase();

const refreshDatabases = async () => {
    isLoading.value = true;
    try {
        const response = await fetchDatabases({
            query: {
                q: searchQuery.value,
                itemsPerPage: itemsPerPage.value,
                page: page.value,
                sortBy: sortBy.value.length > 0 ? sortBy.value[0].key : undefined,
                orderBy: sortBy.value.length > 0 ? sortBy.value[0].order : undefined,
            },
        });
        databases.value = response.data.databases;
        totalDatabases.value = response.data.total;
    } catch (error) {
        console.error(error);
    } finally {
        isLoading.value = false;
    }
};

watchEffect(() => {
    if (searchQuery.value !== '') {
        refreshDatabases();
    }
});

const headers = computed(() => [
    { title: '', key: 'data-table-expand', sortable: false, width: '48px' },
    { title: t("Name"), value: "name", sortable: false },
    { title: t("Actions"), value: "actions", sortable: false, align: "end", width: '120px' },
]);

const flattenedItems = computed(() => {
    const flattened = [];
    databases.value.forEach(database => {
        flattened.push({ ...database, isChild: false });
        if (expandedItems.value.includes(database.id)) {
            database.tables.forEach(table => {
                flattened.push({ name: table.name, isChild: true, parentId: database.id, parentName: database.name });
            });
        }
    });
    return flattened;
});

const addNewDatabase = () => {
    router.push({ name: "admin-databases-add" });
};

const deleteDatabase = async (id) => {
    try {
        await destroyDatabase(id);
        refreshDatabases();
    } catch (error) {
        if (error.response && error.response.status === 422) {
            errorMessage.value = error.response.data.message;
            errorDialog.value = true;
        }
    } finally {
        hideDeleteDialog();
    }
};

const deleteTable = async (tableName, databaseName) => {
    try {
        await destroyTable(databaseName, tableName);
        await refreshDatabases();
        const databaseIndex = databases.value.findIndex(db => db.name === databaseName);
        if (databaseIndex !== -1 && expandedItems.value.includes(databases.value[databaseIndex].id)) {
            const response = await fetchDatabases({
                query: {
                    q: databaseName,
                    itemsPerPage: 1,
                    page: 1,
                },
            });
            if (response.data.databases.length > 0) {
                databases.value[databaseIndex] = response.data.databases[0];
            }
        }
    } catch (error) {
        console.error(`Failed to delete table ${tableName} from database ${databaseName}:`, error);
        if (error.response && error.response.status === 422) {
            errorMessage.value = error.response.data.message;
            errorDialog.value = true;
        }
    } finally {
        hideDeleteDialog();
    }
};

const editDatabase = (id) => {
    router.push({ name: "admin-databases-edit-id", params: { id: id } });
};

const editTable = (databaseName, tableName) => {
    router.push({
        name: "admin-databases-edit-database-table",
        params: { database: databaseName, table: tableName },
    });
};

const isDeleteDialogVisible = ref(false);
const selectedId = ref(null);
const selectedName = ref(null);
const isTableDelete = ref(false);
const deleteMessage = computed(() => {
    if (isTableDelete.value) {
        return t('deleteTableMessage', { name: selectedName.value, database: selectedId.value });
    } else {
        const database = databases.value.find(m => m.id === selectedId.value);
        return database ? t('deleteDatabaseMessage', { name: database.name }) : '';
    }
});

const showDeleteDialog = (id, name = null, isTable = false) => {
    selectedId.value = id;
    selectedName.value = name;
    isTableDelete.value = isTable;
    isDeleteDialogVisible.value = true;
}

const hideDeleteDialog = () => {
    isDeleteDialogVisible.value = false;
}

const toggleExpand = (item) => {
    const index = expandedItems.value.indexOf(item.id);
    if (index === -1) {
        expandedItems.value.push(item.id);
    } else {
        expandedItems.value.splice(index, 1);
    }
};

const hasTablesCh = computed(() => {
    return (item) => item.tables && item.tables.length > 0;
});

const handleDelete = () => {
    if (isTableDelete.value) {
        deleteTable(selectedName.value, selectedId.value);
    } else {
        deleteDatabase(selectedId.value);
    }
};

const addNewTable = (databaseName) => {
    router.push({ name: "admin-databases-add-database", params: { database: databaseName } });
};
</script>

<template>
    <section>
        <VRow>
            <VCol cols="12">
                <VCard :title="$t('Database List')">
                    <VCardText class="d-flex flex-wrap py-4 gap-4">
                        <div class="me-3 d-none d-sm-flex gap-3">
                            <AppSelect :model-value="itemsPerPage" :items="[
                                { value: 10, title: '10' },
                                { value: 25, title: '25' },
                                { value: 50, title: '50' },
                                { value: 100, title: '100' },
                                { value: -1, title: 'All' },
                            ]" style="inline-size: 6.25rem"
                                @update:model-value="itemsPerPage = parseInt($event, 10)" />
                        </div>
                        <VSpacer />

                        <div class="d-flex align-center flex-wrap gap-4">
                            <div style="inline-size: 10rem">
                                <AppTextField v-model="searchQuery" :placeholder="$t('Search')" density="compact" />
                            </div>

                            <VBtn class="d-none d-md-flex" variant="tonal" color="secondary"
                                prepend-icon="tabler-reload" @click="refreshDatabases">
                                {{ $t('Refresh') }}
                            </VBtn>

                            <VBtn @click="addNewDatabase">
                                <VIcon icon="tabler-plus" size="22" class="mr-1" />
                                <span class="d-none d-md-flex">{{ $t('Add Database') }}</span>
                            </VBtn>
                        </div>
                    </VCardText>

                    <VDivider />

                    <VDataTable :headers="headers" :items="databases" :items-per-page="itemsPerPage"
                        :loading="isLoading" :search="searchQuery" class="elevation-1 database-table" item-value="id"
                        loading-text="Loading" @update:options="updateOptions">
                        <template #item="{ item }">
                            <tr>
                                <td v-for="header in headers" :key="header.value" :style="{ width: header.width }">
                                    <template v-if="header.key === 'data-table-expand'">
                                        <VBtn v-if="hasTablesCh(item)" icon variant="text" size="small"
                                            @click="toggleExpand(item)">
                                            <VIcon
                                                :icon="expandedItems.includes(item.id) ? 'tabler-chevron-up' : 'tabler-chevron-down'" />
                                        </VBtn>
                                        <div v-else class="expand-placeholder"></div>
                                    </template>
                                    <template v-else-if="header.value === 'actions'">
                                        <div class="d-flex justify-end">
                                            <IconBtn @click="editDatabase(item.id)">
                                                <VIcon icon="tabler-edit" />
                                            </IconBtn>
                                            <IconBtn @click="showDeleteDialog(item.id)">
                                                <VIcon icon="tabler-trash" />
                                            </IconBtn>
                                            <IconBtn @click="addNewTable(item.name)">
                                                <VIcon icon="tabler-plus" />
                                            </IconBtn>
                                        </div>
                                    </template>
                                    <template v-else>
                                        {{ item[header.value] }}
                                    </template>
                                </td>
                            </tr>
                            <tr v-if="expandedItems.includes(item.id)" v-for="table in item.tables"
                                :key="`${item.id}-${table.name}`" class="child-row">
                                <td></td>
                                <td>{{ table.name }}</td>
                                <td>
                                    <div class="d-flex justify-end">
                                        <IconBtn @click="editTable(item.name, table.name)">
                                            <VIcon icon="tabler-edit" />
                                        </IconBtn>
                                        <IconBtn @click="showDeleteDialog(item.name, table.name, true)">
                                            <VIcon icon="tabler-trash" />
                                        </IconBtn>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </VDataTable>
                </VCard>
            </VCol>
        </VRow>
    </section>
    <DeleteConfirmation :is-dialog-visible="isDeleteDialogVisible" :id="selectedId" :message="deleteMessage"
        @delete="handleDelete" @cancel="hideDeleteDialog" />

    <MessageDialog :is-dialog-visible="errorDialog" :title="'Deletion Failed'" :message="errorMessage"
        @submit="errorDialog = false" />
</template>

<style lang="scss">
.database-table {
    .child-row {
        background-color: #f5f5f5;
    }
}

.expand-placeholder {
    width: 40px;
    display: inline-block;
}

.database-list-name:not(:hover) {
    color: rgba(var(--v-theme-on-background), var(--v-medium-emphasis-opacity));
}
</style>