import _definePage_default_0 from '/home/medsyn/public_html/resources/js/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/home/medsyn/public_html/resources/js/pages/[...error].vue?definePage&vue'
import _definePage_default_3 from '/home/medsyn/public_html/resources/js/pages/access-control.vue?definePage&vue'
import _definePage_default_4 from '/home/medsyn/public_html/resources/js/pages/account/[tab].vue?definePage&vue'
import _definePage_default_5 from '/home/medsyn/public_html/resources/js/pages/activity/index.vue?definePage&vue'
import _definePage_default_6 from '/home/medsyn/public_html/resources/js/pages/admin/databases/index.vue?definePage&vue'
import _definePage_default_7 from '/home/medsyn/public_html/resources/js/pages/admin/databases/add/index.vue?definePage&vue'
import _definePage_default_8 from '/home/medsyn/public_html/resources/js/pages/admin/databases/add/[database].vue?definePage&vue'
import _definePage_default_9 from '/home/medsyn/public_html/resources/js/pages/admin/databases/edit/[database]/[table].vue?definePage&vue'
import _definePage_default_10 from '/home/medsyn/public_html/resources/js/pages/admin/databases/edit/[id].vue?definePage&vue'
import _definePage_default_11 from '/home/medsyn/public_html/resources/js/pages/admin/demos/index.vue?definePage&vue'
import _definePage_default_12 from '/home/medsyn/public_html/resources/js/pages/admin/demos/add/index.vue?definePage&vue'
import _definePage_default_13 from '/home/medsyn/public_html/resources/js/pages/admin/demos/edit/[id].vue?definePage&vue'
import _definePage_default_14 from '/home/medsyn/public_html/resources/js/pages/admin/inquiries/index.vue?definePage&vue'
import _definePage_default_15 from '/home/medsyn/public_html/resources/js/pages/admin/inquiries/view/[id].vue?definePage&vue'
import _definePage_default_16 from '/home/medsyn/public_html/resources/js/pages/admin/licenses/index.vue?definePage&vue'
import _definePage_default_17 from '/home/medsyn/public_html/resources/js/pages/admin/licenses/add/index.vue?definePage&vue'
import _definePage_default_18 from '/home/medsyn/public_html/resources/js/pages/admin/licenses/edit/[id].vue?definePage&vue'
import _definePage_default_19 from '/home/medsyn/public_html/resources/js/pages/admin/notifications/index.vue?definePage&vue'
import _definePage_default_20 from '/home/medsyn/public_html/resources/js/pages/admin/notifications/add/index.vue?definePage&vue'
import _definePage_default_21 from '/home/medsyn/public_html/resources/js/pages/admin/notifications/edit/[id].vue?definePage&vue'
import _definePage_default_22 from '/home/medsyn/public_html/resources/js/pages/admin/owners/index.vue?definePage&vue'
import _definePage_default_23 from '/home/medsyn/public_html/resources/js/pages/admin/owners/edit/[id].vue?definePage&vue'
import _definePage_default_24 from '/home/medsyn/public_html/resources/js/pages/admin/plans/index.vue?definePage&vue'
import _definePage_default_25 from '/home/medsyn/public_html/resources/js/pages/admin/plans/add/index.vue?definePage&vue'
import _definePage_default_26 from '/home/medsyn/public_html/resources/js/pages/admin/plans/edit/[id].vue?definePage&vue'
import _definePage_default_27 from '/home/medsyn/public_html/resources/js/pages/admin/specialties/index.vue?definePage&vue'
import _definePage_default_28 from '/home/medsyn/public_html/resources/js/pages/admin/specialties/add/index.vue?definePage&vue'
import _definePage_default_29 from '/home/medsyn/public_html/resources/js/pages/admin/specialties/edit/[id].vue?definePage&vue'
import _definePage_default_30 from '/home/medsyn/public_html/resources/js/pages/admin/terminal/index.vue?definePage&vue'
import _definePage_default_31 from '/home/medsyn/public_html/resources/js/pages/admin/tutorials/index.vue?definePage&vue'
import _definePage_default_32 from '/home/medsyn/public_html/resources/js/pages/admin/tutorials/add/index.vue?definePage&vue'
import _definePage_default_33 from '/home/medsyn/public_html/resources/js/pages/admin/tutorials/edit/[id].vue?definePage&vue'
import _definePage_default_34 from '/home/medsyn/public_html/resources/js/pages/admin/versions/index.vue?definePage&vue'
import _definePage_default_35 from '/home/medsyn/public_html/resources/js/pages/admin/versions/add/index.vue?definePage&vue'
import _definePage_default_36 from '/home/medsyn/public_html/resources/js/pages/admin/versions/edit/[id].vue?definePage&vue'
import _definePage_default_37 from '/home/medsyn/public_html/resources/js/pages/appointments/index.vue?definePage&vue'
import _definePage_default_38 from '/home/medsyn/public_html/resources/js/pages/appointments/add/index.vue?definePage&vue'
import _definePage_default_39 from '/home/medsyn/public_html/resources/js/pages/appointments/add/[id].vue?definePage&vue'
import _definePage_default_40 from '/home/medsyn/public_html/resources/js/pages/appointments/components/List/Doctor/cs.vue?definePage&vue'
import _definePage_default_41 from '/home/medsyn/public_html/resources/js/pages/appointments/components/List/Doctor/gs.vue?definePage&vue'
import _definePage_default_42 from '/home/medsyn/public_html/resources/js/pages/appointments/components/List/Doctor/ps.vue?definePage&vue'
import _definePage_default_43 from '/home/medsyn/public_html/resources/js/pages/appointments/components/Trash/cs.vue?definePage&vue'
import _definePage_default_44 from '/home/medsyn/public_html/resources/js/pages/appointments/components/Trash/gs.vue?definePage&vue'
import _definePage_default_45 from '/home/medsyn/public_html/resources/js/pages/appointments/components/Trash/ps.vue?definePage&vue'
import _definePage_default_46 from '/home/medsyn/public_html/resources/js/pages/appointments/edit/[id].vue?definePage&vue'
import _definePage_default_47 from '/home/medsyn/public_html/resources/js/pages/appointments/trash.vue?definePage&vue'
import _definePage_default_48 from '/home/medsyn/public_html/resources/js/pages/bills/index.vue?definePage&vue'
import _definePage_default_49 from '/home/medsyn/public_html/resources/js/pages/bills/add/index.vue?definePage&vue'
import _definePage_default_50 from '/home/medsyn/public_html/resources/js/pages/bills/add/[id].vue?definePage&vue'
import _definePage_default_51 from '/home/medsyn/public_html/resources/js/pages/bills/components/Form.vue?definePage&vue'
import _definePage_default_52 from '/home/medsyn/public_html/resources/js/pages/bills/edit/[id].vue?definePage&vue'
import _definePage_default_53 from '/home/medsyn/public_html/resources/js/pages/bills/trash.vue?definePage&vue'
import _definePage_default_54 from '/home/medsyn/public_html/resources/js/pages/calendar/index.vue?definePage&vue'
import _definePage_default_55 from '/home/medsyn/public_html/resources/js/pages/calendar/components/CalendarEventHandler.vue?definePage&vue'
import _definePage_default_56 from '/home/medsyn/public_html/resources/js/pages/chat/index.vue?definePage&vue'
import _definePage_default_57 from '/home/medsyn/public_html/resources/js/pages/chatbot/index.vue?definePage&vue'
import _definePage_default_58 from '/home/medsyn/public_html/resources/js/pages/coming-soon.vue?definePage&vue'
import _definePage_default_59 from '/home/medsyn/public_html/resources/js/pages/consents/index.vue?definePage&vue'
import _definePage_default_60 from '/home/medsyn/public_html/resources/js/pages/consents/add/index.vue?definePage&vue'
import _definePage_default_61 from '/home/medsyn/public_html/resources/js/pages/consents/components/Form.vue?definePage&vue'
import _definePage_default_62 from '/home/medsyn/public_html/resources/js/pages/consents/edit/[id].vue?definePage&vue'
import _definePage_default_63 from '/home/medsyn/public_html/resources/js/pages/consents/trash.vue?definePage&vue'
import _definePage_default_64 from '/home/medsyn/public_html/resources/js/pages/dashboard/index.vue?definePage&vue'
import _definePage_default_65 from '/home/medsyn/public_html/resources/js/pages/dashboard/components/Dashboards/AdminDashboard.vue?definePage&vue'
import _definePage_default_66 from '/home/medsyn/public_html/resources/js/pages/diagnostics/index.vue?definePage&vue'
import _definePage_default_67 from '/home/medsyn/public_html/resources/js/pages/diagnostics/add/index.vue?definePage&vue'
import _definePage_default_68 from '/home/medsyn/public_html/resources/js/pages/diagnostics/components/Form.vue?definePage&vue'
import _definePage_default_69 from '/home/medsyn/public_html/resources/js/pages/diagnostics/edit/[id].vue?definePage&vue'
import _definePage_default_70 from '/home/medsyn/public_html/resources/js/pages/diagnostics/trash.vue?definePage&vue'
import _definePage_default_71 from '/home/medsyn/public_html/resources/js/pages/doctors/index.vue?definePage&vue'
import _definePage_default_72 from '/home/medsyn/public_html/resources/js/pages/doctors/add/index.vue?definePage&vue'
import _definePage_default_73 from '/home/medsyn/public_html/resources/js/pages/doctors/components/Form.vue?definePage&vue'
import _definePage_default_74 from '/home/medsyn/public_html/resources/js/pages/doctors/edit/[id].vue?definePage&vue'
import _definePage_default_75 from '/home/medsyn/public_html/resources/js/pages/doctors/trash.vue?definePage&vue'
import _definePage_default_76 from '/home/medsyn/public_html/resources/js/pages/documentation/index.vue?definePage&vue'
import _definePage_default_77 from '/home/medsyn/public_html/resources/js/pages/documentation/[code].vue?definePage&vue'
import _definePage_default_78 from '/home/medsyn/public_html/resources/js/pages/error.vue?definePage&vue'
import _definePage_default_79 from '/home/medsyn/public_html/resources/js/pages/forgot-password/index.vue?definePage&vue'
import _definePage_default_80 from '/home/medsyn/public_html/resources/js/pages/login/index.vue?definePage&vue'
import _definePage_default_81 from '/home/medsyn/public_html/resources/js/pages/medications/index.vue?definePage&vue'
import _definePage_default_82 from '/home/medsyn/public_html/resources/js/pages/medications/add/index.vue?definePage&vue'
import _definePage_default_83 from '/home/medsyn/public_html/resources/js/pages/medications/components/Form.vue?definePage&vue'
import _definePage_default_84 from '/home/medsyn/public_html/resources/js/pages/medications/edit/[id].vue?definePage&vue'
import _definePage_default_85 from '/home/medsyn/public_html/resources/js/pages/medications/trash.vue?definePage&vue'
import _definePage_default_86 from '/home/medsyn/public_html/resources/js/pages/not-authorized.vue?definePage&vue'
import _definePage_default_87 from '/home/medsyn/public_html/resources/js/pages/not-found.vue?definePage&vue'
import _definePage_default_88 from '/home/medsyn/public_html/resources/js/pages/notifications/index.vue?definePage&vue'
import _definePage_default_89 from '/home/medsyn/public_html/resources/js/pages/patients/index.vue?definePage&vue'
import _definePage_default_90 from '/home/medsyn/public_html/resources/js/pages/patients/[id].vue?definePage&vue'
import _definePage_default_91 from '/home/medsyn/public_html/resources/js/pages/patients/add/index.vue?definePage&vue'
import _definePage_default_92 from '/home/medsyn/public_html/resources/js/pages/patients/components/AddDoctor.vue?definePage&vue'
import _definePage_default_93 from '/home/medsyn/public_html/resources/js/pages/patients/components/List/cs.vue?definePage&vue'
import _definePage_default_94 from '/home/medsyn/public_html/resources/js/pages/patients/components/List/gs.vue?definePage&vue'
import _definePage_default_95 from '/home/medsyn/public_html/resources/js/pages/patients/components/List/ps.vue?definePage&vue'
import _definePage_default_96 from '/home/medsyn/public_html/resources/js/pages/patients/components/Trash/cs.vue?definePage&vue'
import _definePage_default_97 from '/home/medsyn/public_html/resources/js/pages/patients/components/Trash/gs.vue?definePage&vue'
import _definePage_default_98 from '/home/medsyn/public_html/resources/js/pages/patients/components/Trash/ps.vue?definePage&vue'
import _definePage_default_99 from '/home/medsyn/public_html/resources/js/pages/patients/consultation/add/[id].vue?definePage&vue'
import _definePage_default_100 from '/home/medsyn/public_html/resources/js/pages/patients/consultation/components/AddDiagnostic.vue?definePage&vue'
import _definePage_default_101 from '/home/medsyn/public_html/resources/js/pages/patients/consultation/edit/[id].vue?definePage&vue'
import _definePage_default_102 from '/home/medsyn/public_html/resources/js/pages/patients/control/add/[id].vue?definePage&vue'
import _definePage_default_103 from '/home/medsyn/public_html/resources/js/pages/patients/control/edit/[id].vue?definePage&vue'
import _definePage_default_104 from '/home/medsyn/public_html/resources/js/pages/patients/edit/[id].vue?definePage&vue'
import _definePage_default_105 from '/home/medsyn/public_html/resources/js/pages/patients/intervention/add/[id].vue?definePage&vue'
import _definePage_default_106 from '/home/medsyn/public_html/resources/js/pages/patients/intervention/components/AddAnesthesiologist.vue?definePage&vue'
import _definePage_default_107 from '/home/medsyn/public_html/resources/js/pages/patients/intervention/components/AddTechnique.vue?definePage&vue'
import _definePage_default_108 from '/home/medsyn/public_html/resources/js/pages/patients/intervention/edit/[id].vue?definePage&vue'
import _definePage_default_109 from '/home/medsyn/public_html/resources/js/pages/patients/print/[id].vue?definePage&vue'
import _definePage_default_110 from '/home/medsyn/public_html/resources/js/pages/patients/trash.vue?definePage&vue'
import _definePage_default_111 from '/home/medsyn/public_html/resources/js/pages/platform/index.vue?definePage&vue'
import _definePage_default_112 from '/home/medsyn/public_html/resources/js/pages/platform/components/VWindows/Chat.vue?definePage&vue'
import _definePage_default_113 from '/home/medsyn/public_html/resources/js/pages/register/index.vue?definePage&vue'
import _definePage_default_114 from '/home/medsyn/public_html/resources/js/pages/register/doctor.vue?definePage&vue'
import _definePage_default_115 from '/home/medsyn/public_html/resources/js/pages/register/patient.vue?definePage&vue'
import _definePage_default_116 from '/home/medsyn/public_html/resources/js/pages/reset/[email]/[token]/index.vue?definePage&vue'
import _definePage_default_117 from '/home/medsyn/public_html/resources/js/pages/roles/index.vue?definePage&vue'
import _definePage_default_118 from '/home/medsyn/public_html/resources/js/pages/roles/add/index.vue?definePage&vue'
import _definePage_default_119 from '/home/medsyn/public_html/resources/js/pages/roles/components/Form.vue?definePage&vue'
import _definePage_default_120 from '/home/medsyn/public_html/resources/js/pages/roles/edit/[id].vue?definePage&vue'
import _definePage_default_121 from '/home/medsyn/public_html/resources/js/pages/services/index.vue?definePage&vue'
import _definePage_default_122 from '/home/medsyn/public_html/resources/js/pages/services/add/index.vue?definePage&vue'
import _definePage_default_123 from '/home/medsyn/public_html/resources/js/pages/services/components/Form.vue?definePage&vue'
import _definePage_default_124 from '/home/medsyn/public_html/resources/js/pages/services/edit/[id].vue?definePage&vue'
import _definePage_default_125 from '/home/medsyn/public_html/resources/js/pages/services/trash.vue?definePage&vue'
import _definePage_default_126 from '/home/medsyn/public_html/resources/js/pages/settings/[tab].vue?definePage&vue'
import _definePage_default_127 from '/home/medsyn/public_html/resources/js/pages/setup/[email]/[token]/index.vue?definePage&vue'
import _definePage_default_128 from '/home/medsyn/public_html/resources/js/pages/statistics/index.vue?definePage&vue'
import _definePage_default_129 from '/home/medsyn/public_html/resources/js/pages/techniques/index.vue?definePage&vue'
import _definePage_default_130 from '/home/medsyn/public_html/resources/js/pages/techniques/add/index.vue?definePage&vue'
import _definePage_default_131 from '/home/medsyn/public_html/resources/js/pages/techniques/components/Form.vue?definePage&vue'
import _definePage_default_132 from '/home/medsyn/public_html/resources/js/pages/techniques/edit/[id].vue?definePage&vue'
import _definePage_default_133 from '/home/medsyn/public_html/resources/js/pages/techniques/trash.vue?definePage&vue'
import _definePage_default_134 from '/home/medsyn/public_html/resources/js/pages/users/index.vue?definePage&vue'
import _definePage_default_135 from '/home/medsyn/public_html/resources/js/pages/users/add/index.vue?definePage&vue'
import _definePage_default_136 from '/home/medsyn/public_html/resources/js/pages/users/components/Form.vue?definePage&vue'
import _definePage_default_137 from '/home/medsyn/public_html/resources/js/pages/users/edit/[id].vue?definePage&vue'
import _definePage_default_138 from '/home/medsyn/public_html/resources/js/pages/verify/index.vue?definePage&vue'
import _definePage_default_139 from '/home/medsyn/public_html/resources/js/pages/verify/[email]/[token]/index.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'root',
    component: () => import('/home/medsyn/public_html/resources/js/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:error(.*)',
    name: '$error',
    component: () => import('/home/medsyn/public_html/resources/js/pages/[...error].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  _mergeRouteRecord(
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('/home/medsyn/public_html/resources/js/pages/access-control.vue'),
    /* no children */
  },
  _definePage_default_3
  ),
  {
    path: '/account',
    /* internal name: 'account' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':tab',
        name: 'account-tab',
        component: () => import('/home/medsyn/public_html/resources/js/pages/account/[tab].vue'),
        /* no children */
      },
  _definePage_default_4
  ),
      {
        path: 'components',
        /* internal name: 'account-components' */
        /* no component */
        children: [
          {
            path: 'Billing',
            name: 'account-components-billing',
            component: () => import('/home/medsyn/public_html/resources/js/pages/account/components/Billing.vue'),
            /* no children */
          },
          {
            path: 'Doctor',
            name: 'account-components-doctor',
            component: () => import('/home/medsyn/public_html/resources/js/pages/account/components/Doctor.vue'),
            /* no children */
          },
          {
            path: 'General',
            name: 'account-components-general',
            component: () => import('/home/medsyn/public_html/resources/js/pages/account/components/General.vue'),
            /* no children */
          },
          {
            path: 'Security',
            name: 'account-components-security',
            component: () => import('/home/medsyn/public_html/resources/js/pages/account/components/Security.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/activity',
    /* internal name: 'activity' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'activity',
        component: () => import('/home/medsyn/public_html/resources/js/pages/activity/index.vue'),
        /* no children */
      },
  _definePage_default_5
  )
    ],
  },
  {
    path: '/admin',
    /* internal name: 'admin' */
    /* no component */
    children: [
      {
        path: 'databases',
        /* internal name: 'admin-databases' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-databases',
            component: () => import('/home/medsyn/public_html/resources/js/pages/admin/databases/index.vue'),
            /* no children */
          },
  _definePage_default_6
  ),
          {
            path: 'add',
            /* internal name: 'admin-databases-add' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'admin-databases-add',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/databases/add/index.vue'),
                /* no children */
              },
  _definePage_default_7
  ),
  _mergeRouteRecord(
              {
                path: ':database',
                name: 'admin-databases-add-database',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/databases/add/[database].vue'),
                /* no children */
              },
  _definePage_default_8
  )
            ],
          },
          {
            path: 'components',
            /* internal name: 'admin-databases-components' */
            /* no component */
            children: [
              {
                path: 'DatabaseForm',
                name: 'admin-databases-components-database-form',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/databases/components/DatabaseForm.vue'),
                /* no children */
              },
              {
                path: 'TableForm',
                name: 'admin-databases-components-table-form',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/databases/components/TableForm.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'edit',
            /* internal name: 'admin-databases-edit' */
            /* no component */
            children: [
              {
                path: ':database',
                /* internal name: 'admin-databases-edit-database' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: ':table',
                    name: 'admin-databases-edit-database-table',
                    component: () => import('/home/medsyn/public_html/resources/js/pages/admin/databases/edit/[database]/[table].vue'),
                    /* no children */
                  },
  _definePage_default_9
  )
                ],
              },
  _mergeRouteRecord(
              {
                path: ':id',
                name: 'admin-databases-edit-id',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/databases/edit/[id].vue'),
                /* no children */
              },
  _definePage_default_10
  )
            ],
          }
        ],
      },
      {
        path: 'demos',
        /* internal name: 'admin-demos' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-demos',
            component: () => import('/home/medsyn/public_html/resources/js/pages/admin/demos/index.vue'),
            /* no children */
          },
  _definePage_default_11
  ),
          {
            path: 'add',
            /* internal name: 'admin-demos-add' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'admin-demos-add',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/demos/add/index.vue'),
                /* no children */
              },
  _definePage_default_12
  )
            ],
          },
          {
            path: 'components',
            /* internal name: 'admin-demos-components' */
            /* no component */
            children: [
              {
                path: 'Form',
                name: 'admin-demos-components-form',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/demos/components/Form.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'edit',
            /* internal name: 'admin-demos-edit' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: 'admin-demos-edit-id',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/demos/edit/[id].vue'),
                /* no children */
              },
  _definePage_default_13
  )
            ],
          }
        ],
      },
      {
        path: 'inquiries',
        /* internal name: 'admin-inquiries' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-inquiries',
            component: () => import('/home/medsyn/public_html/resources/js/pages/admin/inquiries/index.vue'),
            /* no children */
          },
  _definePage_default_14
  ),
          {
            path: 'view',
            /* internal name: 'admin-inquiries-view' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: 'admin-inquiries-view-id',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/inquiries/view/[id].vue'),
                /* no children */
              },
  _definePage_default_15
  )
            ],
          }
        ],
      },
      {
        path: 'licenses',
        /* internal name: 'admin-licenses' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-licenses',
            component: () => import('/home/medsyn/public_html/resources/js/pages/admin/licenses/index.vue'),
            /* no children */
          },
  _definePage_default_16
  ),
          {
            path: 'add',
            /* internal name: 'admin-licenses-add' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'admin-licenses-add',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/licenses/add/index.vue'),
                /* no children */
              },
  _definePage_default_17
  )
            ],
          },
          {
            path: 'components',
            /* internal name: 'admin-licenses-components' */
            /* no component */
            children: [
              {
                path: 'Form',
                name: 'admin-licenses-components-form',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/licenses/components/Form.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'edit',
            /* internal name: 'admin-licenses-edit' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: 'admin-licenses-edit-id',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/licenses/edit/[id].vue'),
                /* no children */
              },
  _definePage_default_18
  )
            ],
          }
        ],
      },
      {
        path: 'notifications',
        /* internal name: 'admin-notifications' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-notifications',
            component: () => import('/home/medsyn/public_html/resources/js/pages/admin/notifications/index.vue'),
            /* no children */
          },
  _definePage_default_19
  ),
          {
            path: 'add',
            /* internal name: 'admin-notifications-add' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'admin-notifications-add',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/notifications/add/index.vue'),
                /* no children */
              },
  _definePage_default_20
  )
            ],
          },
          {
            path: 'components',
            /* internal name: 'admin-notifications-components' */
            /* no component */
            children: [
              {
                path: 'Form',
                name: 'admin-notifications-components-form',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/notifications/components/Form.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'edit',
            /* internal name: 'admin-notifications-edit' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: 'admin-notifications-edit-id',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/notifications/edit/[id].vue'),
                /* no children */
              },
  _definePage_default_21
  )
            ],
          }
        ],
      },
      {
        path: 'owners',
        /* internal name: 'admin-owners' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-owners',
            component: () => import('/home/medsyn/public_html/resources/js/pages/admin/owners/index.vue'),
            /* no children */
          },
  _definePage_default_22
  ),
          {
            path: 'components',
            /* internal name: 'admin-owners-components' */
            /* no component */
            children: [
              {
                path: 'Form',
                name: 'admin-owners-components-form',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/owners/components/Form.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'edit',
            /* internal name: 'admin-owners-edit' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: 'admin-owners-edit-id',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/owners/edit/[id].vue'),
                /* no children */
              },
  _definePage_default_23
  )
            ],
          }
        ],
      },
      {
        path: 'plans',
        /* internal name: 'admin-plans' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-plans',
            component: () => import('/home/medsyn/public_html/resources/js/pages/admin/plans/index.vue'),
            /* no children */
          },
  _definePage_default_24
  ),
          {
            path: 'add',
            /* internal name: 'admin-plans-add' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'admin-plans-add',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/plans/add/index.vue'),
                /* no children */
              },
  _definePage_default_25
  )
            ],
          },
          {
            path: 'components',
            /* internal name: 'admin-plans-components' */
            /* no component */
            children: [
              {
                path: 'AccessCheckboxes',
                name: 'admin-plans-components-access-checkboxes',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/plans/components/AccessCheckboxes.vue'),
                /* no children */
              },
              {
                path: 'Form',
                name: 'admin-plans-components-form',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/plans/components/Form.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'edit',
            /* internal name: 'admin-plans-edit' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: 'admin-plans-edit-id',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/plans/edit/[id].vue'),
                /* no children */
              },
  _definePage_default_26
  )
            ],
          }
        ],
      },
      {
        path: 'specialties',
        /* internal name: 'admin-specialties' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-specialties',
            component: () => import('/home/medsyn/public_html/resources/js/pages/admin/specialties/index.vue'),
            /* no children */
          },
  _definePage_default_27
  ),
          {
            path: 'add',
            /* internal name: 'admin-specialties-add' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'admin-specialties-add',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/specialties/add/index.vue'),
                /* no children */
              },
  _definePage_default_28
  )
            ],
          },
          {
            path: 'components',
            /* internal name: 'admin-specialties-components' */
            /* no component */
            children: [
              {
                path: 'Form',
                name: 'admin-specialties-components-form',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/specialties/components/Form.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'edit',
            /* internal name: 'admin-specialties-edit' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: 'admin-specialties-edit-id',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/specialties/edit/[id].vue'),
                /* no children */
              },
  _definePage_default_29
  )
            ],
          }
        ],
      },
      {
        path: 'terminal',
        /* internal name: 'admin-terminal' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-terminal',
            component: () => import('/home/medsyn/public_html/resources/js/pages/admin/terminal/index.vue'),
            /* no children */
          },
  _definePage_default_30
  )
        ],
      },
      {
        path: 'tutorials',
        /* internal name: 'admin-tutorials' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-tutorials',
            component: () => import('/home/medsyn/public_html/resources/js/pages/admin/tutorials/index.vue'),
            /* no children */
          },
  _definePage_default_31
  ),
          {
            path: 'add',
            /* internal name: 'admin-tutorials-add' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'admin-tutorials-add',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/tutorials/add/index.vue'),
                /* no children */
              },
  _definePage_default_32
  )
            ],
          },
          {
            path: 'components',
            /* internal name: 'admin-tutorials-components' */
            /* no component */
            children: [
              {
                path: 'Form',
                name: 'admin-tutorials-components-form',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/tutorials/components/Form.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'edit',
            /* internal name: 'admin-tutorials-edit' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: 'admin-tutorials-edit-id',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/tutorials/edit/[id].vue'),
                /* no children */
              },
  _definePage_default_33
  )
            ],
          }
        ],
      },
      {
        path: 'versions',
        /* internal name: 'admin-versions' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-versions',
            component: () => import('/home/medsyn/public_html/resources/js/pages/admin/versions/index.vue'),
            /* no children */
          },
  _definePage_default_34
  ),
          {
            path: 'add',
            /* internal name: 'admin-versions-add' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'admin-versions-add',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/versions/add/index.vue'),
                /* no children */
              },
  _definePage_default_35
  )
            ],
          },
          {
            path: 'components',
            /* internal name: 'admin-versions-components' */
            /* no component */
            children: [
              {
                path: 'Form',
                name: 'admin-versions-components-form',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/versions/components/Form.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'edit',
            /* internal name: 'admin-versions-edit' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: 'admin-versions-edit-id',
                component: () => import('/home/medsyn/public_html/resources/js/pages/admin/versions/edit/[id].vue'),
                /* no children */
              },
  _definePage_default_36
  )
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/appointments',
    /* internal name: 'appointments' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'appointments',
        component: () => import('/home/medsyn/public_html/resources/js/pages/appointments/index.vue'),
        /* no children */
      },
  _definePage_default_37
  ),
      {
        path: 'add',
        /* internal name: 'appointments-add' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'appointments-add',
            component: () => import('/home/medsyn/public_html/resources/js/pages/appointments/add/index.vue'),
            /* no children */
          },
  _definePage_default_38
  ),
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'appointments-add-id',
            component: () => import('/home/medsyn/public_html/resources/js/pages/appointments/add/[id].vue'),
            /* no children */
          },
  _definePage_default_39
  )
        ],
      },
      {
        path: 'components',
        /* internal name: 'appointments-components' */
        /* no component */
        children: [
          {
            path: 'Forms',
            /* internal name: 'appointments-components-forms' */
            /* no component */
            children: [
              {
                path: 'cs',
                name: 'appointments-components-forms-cs',
                component: () => import('/home/medsyn/public_html/resources/js/pages/appointments/components/Forms/cs.vue'),
                /* no children */
              },
              {
                path: 'gs',
                name: 'appointments-components-forms-gs',
                component: () => import('/home/medsyn/public_html/resources/js/pages/appointments/components/Forms/gs.vue'),
                /* no children */
              },
              {
                path: 'ps',
                name: 'appointments-components-forms-ps',
                component: () => import('/home/medsyn/public_html/resources/js/pages/appointments/components/Forms/ps.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'List',
            /* internal name: 'appointments-components-list' */
            /* no component */
            children: [
              {
                path: 'Doctor',
                /* internal name: 'appointments-components-list-doctor' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: 'cs',
                    name: 'appointments-components-list-doctor-cs',
                    component: () => import('/home/medsyn/public_html/resources/js/pages/appointments/components/List/Doctor/cs.vue'),
                    /* no children */
                  },
  _definePage_default_40
  ),
  _mergeRouteRecord(
                  {
                    path: 'gs',
                    name: 'appointments-components-list-doctor-gs',
                    component: () => import('/home/medsyn/public_html/resources/js/pages/appointments/components/List/Doctor/gs.vue'),
                    /* no children */
                  },
  _definePage_default_41
  ),
  _mergeRouteRecord(
                  {
                    path: 'ps',
                    name: 'appointments-components-list-doctor-ps',
                    component: () => import('/home/medsyn/public_html/resources/js/pages/appointments/components/List/Doctor/ps.vue'),
                    /* no children */
                  },
  _definePage_default_42
  )
                ],
              },
              {
                path: 'Patient',
                /* internal name: 'appointments-components-list-patient' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'appointments-components-list-patient',
                    component: () => import('/home/medsyn/public_html/resources/js/pages/appointments/components/List/Patient/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'test',
                    name: 'appointments-components-list-patient-test',
                    component: () => import('/home/medsyn/public_html/resources/js/pages/appointments/components/List/Patient/test.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'Trash',
            /* internal name: 'appointments-components-trash' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: 'cs',
                name: 'appointments-components-trash-cs',
                component: () => import('/home/medsyn/public_html/resources/js/pages/appointments/components/Trash/cs.vue'),
                /* no children */
              },
  _definePage_default_43
  ),
  _mergeRouteRecord(
              {
                path: 'gs',
                name: 'appointments-components-trash-gs',
                component: () => import('/home/medsyn/public_html/resources/js/pages/appointments/components/Trash/gs.vue'),
                /* no children */
              },
  _definePage_default_44
  ),
  _mergeRouteRecord(
              {
                path: 'ps',
                name: 'appointments-components-trash-ps',
                component: () => import('/home/medsyn/public_html/resources/js/pages/appointments/components/Trash/ps.vue'),
                /* no children */
              },
  _definePage_default_45
  )
            ],
          }
        ],
      },
      {
        path: 'edit',
        /* internal name: 'appointments-edit' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'appointments-edit-id',
            component: () => import('/home/medsyn/public_html/resources/js/pages/appointments/edit/[id].vue'),
            /* no children */
          },
  _definePage_default_46
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'trash',
        name: 'appointments-trash',
        component: () => import('/home/medsyn/public_html/resources/js/pages/appointments/trash.vue'),
        /* no children */
      },
  _definePage_default_47
  )
    ],
  },
  {
    path: '/bills',
    /* internal name: 'bills' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'bills',
        component: () => import('/home/medsyn/public_html/resources/js/pages/bills/index.vue'),
        /* no children */
      },
  _definePage_default_48
  ),
      {
        path: 'add',
        /* internal name: 'bills-add' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'bills-add',
            component: () => import('/home/medsyn/public_html/resources/js/pages/bills/add/index.vue'),
            /* no children */
          },
  _definePage_default_49
  ),
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'bills-add-id',
            component: () => import('/home/medsyn/public_html/resources/js/pages/bills/add/[id].vue'),
            /* no children */
          },
  _definePage_default_50
  )
        ],
      },
      {
        path: 'components',
        /* internal name: 'bills-components' */
        /* no component */
        children: [
          {
            path: 'AddService',
            name: 'bills-components-add-service',
            component: () => import('/home/medsyn/public_html/resources/js/pages/bills/components/AddService.vue'),
            /* no children */
          },
          {
            path: 'ClientForm',
            name: 'bills-components-client-form',
            component: () => import('/home/medsyn/public_html/resources/js/pages/bills/components/ClientForm.vue'),
            /* no children */
          },
  _mergeRouteRecord(
          {
            path: 'Form',
            name: 'bills-components-form',
            component: () => import('/home/medsyn/public_html/resources/js/pages/bills/components/Form.vue'),
            /* no children */
          },
  _definePage_default_51
  ),
          {
            path: 'Lists',
            /* internal name: 'bills-components-lists' */
            /* no component */
            children: [
              {
                path: 'Doctor',
                name: 'bills-components-lists-doctor',
                component: () => import('/home/medsyn/public_html/resources/js/pages/bills/components/Lists/Doctor.vue'),
                /* no children */
              },
              {
                path: 'Patient',
                name: 'bills-components-lists-patient',
                component: () => import('/home/medsyn/public_html/resources/js/pages/bills/components/Lists/Patient.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'ServicesForm',
            name: 'bills-components-services-form',
            component: () => import('/home/medsyn/public_html/resources/js/pages/bills/components/ServicesForm.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'edit',
        /* internal name: 'bills-edit' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'bills-edit-id',
            component: () => import('/home/medsyn/public_html/resources/js/pages/bills/edit/[id].vue'),
            /* no children */
          },
  _definePage_default_52
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'trash',
        name: 'bills-trash',
        component: () => import('/home/medsyn/public_html/resources/js/pages/bills/trash.vue'),
        /* no children */
      },
  _definePage_default_53
  )
    ],
  },
  {
    path: '/calendar',
    /* internal name: 'calendar' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'calendar',
        component: () => import('/home/medsyn/public_html/resources/js/pages/calendar/index.vue'),
        /* no children */
      },
  _definePage_default_54
  ),
      {
        path: 'components',
        /* internal name: 'calendar-components' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'CalendarEventHandler',
            name: 'calendar-components-calendar-event-handler',
            component: () => import('/home/medsyn/public_html/resources/js/pages/calendar/components/CalendarEventHandler.vue'),
            /* no children */
          },
  _definePage_default_55
  )
        ],
      }
    ],
  },
  {
    path: '/chat',
    /* internal name: 'chat' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'chat',
        component: () => import('/home/medsyn/public_html/resources/js/pages/chat/index.vue'),
        /* no children */
      },
  _definePage_default_56
  )
    ],
  },
  {
    path: '/chatbot',
    /* internal name: 'chatbot' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'chatbot',
        component: () => import('/home/medsyn/public_html/resources/js/pages/chatbot/index.vue'),
        /* no children */
      },
  _definePage_default_57
  ),
      {
        path: 'components',
        /* internal name: 'chatbot-components' */
        /* no component */
        children: [
          {
            path: 'ChatInput',
            name: 'chatbot-components-chat-input',
            component: () => import('/home/medsyn/public_html/resources/js/pages/chatbot/components/ChatInput.vue'),
            /* no children */
          },
          {
            path: 'ChatMenu',
            name: 'chatbot-components-chat-menu',
            component: () => import('/home/medsyn/public_html/resources/js/pages/chatbot/components/ChatMenu.vue'),
            /* no children */
          },
          {
            path: 'ChatMessages',
            name: 'chatbot-components-chat-messages',
            component: () => import('/home/medsyn/public_html/resources/js/pages/chatbot/components/ChatMessages.vue'),
            /* no children */
          },
          {
            path: 'LoaderAnimation',
            name: 'chatbot-components-loader-animation',
            component: () => import('/home/medsyn/public_html/resources/js/pages/chatbot/components/LoaderAnimation.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/coming-soon',
    name: 'coming-soon',
    component: () => import('/home/medsyn/public_html/resources/js/pages/coming-soon.vue'),
    /* no children */
  },
  _definePage_default_58
  ),
  {
    path: '/consents',
    /* internal name: 'consents' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'consents',
        component: () => import('/home/medsyn/public_html/resources/js/pages/consents/index.vue'),
        /* no children */
      },
  _definePage_default_59
  ),
      {
        path: 'add',
        /* internal name: 'consents-add' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'consents-add',
            component: () => import('/home/medsyn/public_html/resources/js/pages/consents/add/index.vue'),
            /* no children */
          },
  _definePage_default_60
  )
        ],
      },
      {
        path: 'components',
        /* internal name: 'consents-components' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'Form',
            name: 'consents-components-form',
            component: () => import('/home/medsyn/public_html/resources/js/pages/consents/components/Form.vue'),
            /* no children */
          },
  _definePage_default_61
  )
        ],
      },
      {
        path: 'edit',
        /* internal name: 'consents-edit' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'consents-edit-id',
            component: () => import('/home/medsyn/public_html/resources/js/pages/consents/edit/[id].vue'),
            /* no children */
          },
  _definePage_default_62
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'trash',
        name: 'consents-trash',
        component: () => import('/home/medsyn/public_html/resources/js/pages/consents/trash.vue'),
        /* no children */
      },
  _definePage_default_63
  )
    ],
  },
  {
    path: '/dashboard',
    /* internal name: 'dashboard' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'dashboard',
        component: () => import('/home/medsyn/public_html/resources/js/pages/dashboard/index.vue'),
        /* no children */
      },
  _definePage_default_64
  ),
      {
        path: 'components',
        /* internal name: 'dashboard-components' */
        /* no component */
        children: [
          {
            path: 'Dashboards',
            /* internal name: 'dashboard-components-dashboards' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: 'AdminDashboard',
                name: 'dashboard-components-dashboards-admin-dashboard',
                component: () => import('/home/medsyn/public_html/resources/js/pages/dashboard/components/Dashboards/AdminDashboard.vue'),
                /* no children */
              },
  _definePage_default_65
  ),
              {
                path: 'PatientDashboard',
                name: 'dashboard-components-dashboards-patient-dashboard',
                component: () => import('/home/medsyn/public_html/resources/js/pages/dashboard/components/Dashboards/PatientDashboard.vue'),
                /* no children */
              },
              {
                path: 'UserDoctorDashboard',
                name: 'dashboard-components-dashboards-user-doctor-dashboard',
                component: () => import('/home/medsyn/public_html/resources/js/pages/dashboard/components/Dashboards/UserDoctorDashboard.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'Elements',
            /* internal name: 'dashboard-components-elements' */
            /* no component */
            children: [
              {
                path: 'CardButtons',
                name: 'dashboard-components-elements-card-buttons',
                component: () => import('/home/medsyn/public_html/resources/js/pages/dashboard/components/Elements/CardButtons.vue'),
                /* no children */
              },
              {
                path: 'CardSwipe',
                name: 'dashboard-components-elements-card-swipe',
                component: () => import('/home/medsyn/public_html/resources/js/pages/dashboard/components/Elements/CardSwipe.vue'),
                /* no children */
              },
              {
                path: 'EventTimeline',
                name: 'dashboard-components-elements-event-timeline',
                component: () => import('/home/medsyn/public_html/resources/js/pages/dashboard/components/Elements/EventTimeline.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'Tables',
            /* internal name: 'dashboard-components-tables' */
            /* no component */
            children: [
              {
                path: 'AppointmentTable',
                name: 'dashboard-components-tables-appointment-table',
                component: () => import('/home/medsyn/public_html/resources/js/pages/dashboard/components/Tables/AppointmentTable.vue'),
                /* no children */
              },
              {
                path: 'PatientTable',
                name: 'dashboard-components-tables-patient-table',
                component: () => import('/home/medsyn/public_html/resources/js/pages/dashboard/components/Tables/PatientTable.vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/diagnostics',
    /* internal name: 'diagnostics' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'diagnostics',
        component: () => import('/home/medsyn/public_html/resources/js/pages/diagnostics/index.vue'),
        /* no children */
      },
  _definePage_default_66
  ),
      {
        path: 'add',
        /* internal name: 'diagnostics-add' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'diagnostics-add',
            component: () => import('/home/medsyn/public_html/resources/js/pages/diagnostics/add/index.vue'),
            /* no children */
          },
  _definePage_default_67
  )
        ],
      },
      {
        path: 'components',
        /* internal name: 'diagnostics-components' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'Form',
            name: 'diagnostics-components-form',
            component: () => import('/home/medsyn/public_html/resources/js/pages/diagnostics/components/Form.vue'),
            /* no children */
          },
  _definePage_default_68
  )
        ],
      },
      {
        path: 'edit',
        /* internal name: 'diagnostics-edit' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'diagnostics-edit-id',
            component: () => import('/home/medsyn/public_html/resources/js/pages/diagnostics/edit/[id].vue'),
            /* no children */
          },
  _definePage_default_69
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'trash',
        name: 'diagnostics-trash',
        component: () => import('/home/medsyn/public_html/resources/js/pages/diagnostics/trash.vue'),
        /* no children */
      },
  _definePage_default_70
  )
    ],
  },
  {
    path: '/doctors',
    /* internal name: 'doctors' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'doctors',
        component: () => import('/home/medsyn/public_html/resources/js/pages/doctors/index.vue'),
        /* no children */
      },
  _definePage_default_71
  ),
      {
        path: 'add',
        /* internal name: 'doctors-add' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'doctors-add',
            component: () => import('/home/medsyn/public_html/resources/js/pages/doctors/add/index.vue'),
            /* no children */
          },
  _definePage_default_72
  )
        ],
      },
      {
        path: 'components',
        /* internal name: 'doctors-components' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'Form',
            name: 'doctors-components-form',
            component: () => import('/home/medsyn/public_html/resources/js/pages/doctors/components/Form.vue'),
            /* no children */
          },
  _definePage_default_73
  )
        ],
      },
      {
        path: 'edit',
        /* internal name: 'doctors-edit' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'doctors-edit-id',
            component: () => import('/home/medsyn/public_html/resources/js/pages/doctors/edit/[id].vue'),
            /* no children */
          },
  _definePage_default_74
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'trash',
        name: 'doctors-trash',
        component: () => import('/home/medsyn/public_html/resources/js/pages/doctors/trash.vue'),
        /* no children */
      },
  _definePage_default_75
  )
    ],
  },
  {
    path: '/documentation',
    /* internal name: 'documentation' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'documentation',
        component: () => import('/home/medsyn/public_html/resources/js/pages/documentation/index.vue'),
        /* no children */
      },
  _definePage_default_76
  ),
  _mergeRouteRecord(
      {
        path: ':code',
        name: 'documentation-code',
        component: () => import('/home/medsyn/public_html/resources/js/pages/documentation/[code].vue'),
        /* no children */
      },
  _definePage_default_77
  ),
      {
        path: 'components',
        /* internal name: 'documentation-components' */
        /* no component */
        children: [
          {
            path: 'Tutorials',
            name: 'documentation-components-tutorials',
            component: () => import('/home/medsyn/public_html/resources/js/pages/documentation/components/Tutorials.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/error',
    name: 'error',
    component: () => import('/home/medsyn/public_html/resources/js/pages/error.vue'),
    /* no children */
  },
  _definePage_default_78
  ),
  {
    path: '/forgot-password',
    /* internal name: 'forgot-password' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'forgot-password',
        component: () => import('/home/medsyn/public_html/resources/js/pages/forgot-password/index.vue'),
        /* no children */
      },
  _definePage_default_79
  )
    ],
  },
  {
    path: '/login',
    /* internal name: 'login' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'login',
        component: () => import('/home/medsyn/public_html/resources/js/pages/login/index.vue'),
        /* no children */
      },
  _definePage_default_80
  )
    ],
  },
  {
    path: '/medications',
    /* internal name: 'medications' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'medications',
        component: () => import('/home/medsyn/public_html/resources/js/pages/medications/index.vue'),
        /* no children */
      },
  _definePage_default_81
  ),
      {
        path: 'add',
        /* internal name: 'medications-add' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'medications-add',
            component: () => import('/home/medsyn/public_html/resources/js/pages/medications/add/index.vue'),
            /* no children */
          },
  _definePage_default_82
  )
        ],
      },
      {
        path: 'components',
        /* internal name: 'medications-components' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'Form',
            name: 'medications-components-form',
            component: () => import('/home/medsyn/public_html/resources/js/pages/medications/components/Form.vue'),
            /* no children */
          },
  _definePage_default_83
  )
        ],
      },
      {
        path: 'edit',
        /* internal name: 'medications-edit' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'medications-edit-id',
            component: () => import('/home/medsyn/public_html/resources/js/pages/medications/edit/[id].vue'),
            /* no children */
          },
  _definePage_default_84
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'trash',
        name: 'medications-trash',
        component: () => import('/home/medsyn/public_html/resources/js/pages/medications/trash.vue'),
        /* no children */
      },
  _definePage_default_85
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('/home/medsyn/public_html/resources/js/pages/not-authorized.vue'),
    /* no children */
  },
  _definePage_default_86
  ),
  _mergeRouteRecord(
  {
    path: '/not-found',
    name: 'not-found',
    component: () => import('/home/medsyn/public_html/resources/js/pages/not-found.vue'),
    /* no children */
  },
  _definePage_default_87
  ),
  {
    path: '/notifications',
    /* internal name: 'notifications' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'notifications',
        component: () => import('/home/medsyn/public_html/resources/js/pages/notifications/index.vue'),
        /* no children */
      },
  _definePage_default_88
  )
    ],
  },
  {
    path: '/notivue',
    /* internal name: 'notivue' */
    /* no component */
    children: [
      {
        path: '',
        name: 'notivue',
        component: () => import('/home/medsyn/public_html/resources/js/pages/notivue/index.vue'),
        /* no children */
      },
      {
        path: 'test',
        name: 'notivue-test',
        component: () => import('/home/medsyn/public_html/resources/js/pages/notivue/test.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/patients',
    /* internal name: 'patients' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'patients',
        component: () => import('/home/medsyn/public_html/resources/js/pages/patients/index.vue'),
        /* no children */
      },
  _definePage_default_89
  ),
  _mergeRouteRecord(
      {
        path: ':id',
        name: 'patients-id',
        component: () => import('/home/medsyn/public_html/resources/js/pages/patients/[id].vue'),
        /* no children */
      },
  _definePage_default_90
  ),
      {
        path: 'add',
        /* internal name: 'patients-add' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'patients-add',
            component: () => import('/home/medsyn/public_html/resources/js/pages/patients/add/index.vue'),
            /* no children */
          },
  _definePage_default_91
  )
        ],
      },
      {
        path: 'components',
        /* internal name: 'patients-components' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'AddDoctor',
            name: 'patients-components-add-doctor',
            component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/AddDoctor.vue'),
            /* no children */
          },
  _definePage_default_92
  ),
          {
            path: 'BioPanel',
            name: 'patients-components-bio-panel',
            component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/BioPanel.vue'),
            /* no children */
          },
          {
            path: 'Connect',
            name: 'patients-components-connect',
            component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Connect.vue'),
            /* no children */
          },
          {
            path: 'Dialogs',
            /* internal name: 'patients-components-dialogs' */
            /* no component */
            children: [
              {
                path: 'AdmissionLetter',
                name: 'patients-components-dialogs-admission-letter',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Dialogs/AdmissionLetter.vue'),
                /* no children */
              },
              {
                path: 'ConfidentialLetter',
                name: 'patients-components-dialogs-confidential-letter',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Dialogs/ConfidentialLetter.vue'),
                /* no children */
              },
              {
                path: 'ConsentForm',
                name: 'patients-components-dialogs-consent-form',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Dialogs/ConsentForm.vue'),
                /* no children */
              },
              {
                path: 'DoctorLetter',
                name: 'patients-components-dialogs-doctor-letter',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Dialogs/DoctorLetter.vue'),
                /* no children */
              },
              {
                path: 'HospitalReport',
                name: 'patients-components-dialogs-hospital-report',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Dialogs/HospitalReport.vue'),
                /* no children */
              },
              {
                path: 'MedicalCertificate',
                name: 'patients-components-dialogs-medical-certificate',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Dialogs/MedicalCertificate.vue'),
                /* no children */
              },
              {
                path: 'OperativeReport',
                name: 'patients-components-dialogs-operative-report',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Dialogs/OperativeReport.vue'),
                /* no children */
              },
              {
                path: 'PreoperativeAssessment',
                name: 'patients-components-dialogs-preoperative-assessment',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Dialogs/PreoperativeAssessment.vue'),
                /* no children */
              },
              {
                path: 'Prescription',
                name: 'patients-components-dialogs-prescription',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Dialogs/Prescription.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'Forms',
            /* internal name: 'patients-components-forms' */
            /* no component */
            children: [
              {
                path: 'Background',
                name: 'patients-components-forms-background',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Forms/Background.vue'),
                /* no children */
              },
              {
                path: 'cs',
                /* internal name: 'patients-components-forms-cs' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'patients-components-forms-cs',
                    component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Forms/cs/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'Details',
                    name: 'patients-components-forms-cs-details',
                    component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Forms/cs/Details.vue'),
                    /* no children */
                  },
                  {
                    path: 'Patient',
                    name: 'patients-components-forms-cs-patient',
                    component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Forms/cs/Patient.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'gs',
                /* internal name: 'patients-components-forms-gs' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'patients-components-forms-gs',
                    component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Forms/gs/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'Details',
                    name: 'patients-components-forms-gs-details',
                    component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Forms/gs/Details.vue'),
                    /* no children */
                  },
                  {
                    path: 'Patient',
                    name: 'patients-components-forms-gs-patient',
                    component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Forms/gs/Patient.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'Multimedia',
                name: 'patients-components-forms-multimedia',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Forms/Multimedia.vue'),
                /* no children */
              },
              {
                path: 'ps',
                /* internal name: 'patients-components-forms-ps' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'patients-components-forms-ps',
                    component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Forms/ps/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'Parent',
                    name: 'patients-components-forms-ps-parent',
                    component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Forms/ps/Parent.vue'),
                    /* no children */
                  },
                  {
                    path: 'Patient',
                    name: 'patients-components-forms-ps-patient',
                    component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Forms/ps/Patient.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'Header',
            name: 'patients-components-header',
            component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Header.vue'),
            /* no children */
          },
          {
            path: 'List',
            /* internal name: 'patients-components-list' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: 'cs',
                name: 'patients-components-list-cs',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/List/cs.vue'),
                /* no children */
              },
  _definePage_default_93
  ),
  _mergeRouteRecord(
              {
                path: 'gs',
                name: 'patients-components-list-gs',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/List/gs.vue'),
                /* no children */
              },
  _definePage_default_94
  ),
  _mergeRouteRecord(
              {
                path: 'ps',
                name: 'patients-components-list-ps',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/List/ps.vue'),
                /* no children */
              },
  _definePage_default_95
  )
            ],
          },
          {
            path: 'Panels',
            /* internal name: 'patients-components-panels' */
            /* no component */
            children: [
              {
                path: 'cs',
                name: 'patients-components-panels-cs',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Panels/cs.vue'),
                /* no children */
              },
              {
                path: 'gs',
                name: 'patients-components-panels-gs',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Panels/gs.vue'),
                /* no children */
              },
              {
                path: 'ps',
                name: 'patients-components-panels-ps',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Panels/ps.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'PatientStatistics',
            name: 'patients-components-patient-statistics',
            component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/PatientStatistics.vue'),
            /* no children */
          },
          {
            path: 'PatientTabs',
            /* internal name: 'patients-components-patient-tabs' */
            /* no component */
            children: [
              {
                path: 'Appointments',
                name: 'patients-components-patient-tabs-appointments',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/PatientTabs/Appointments.vue'),
                /* no children */
              },
              {
                path: 'Bills',
                name: 'patients-components-patient-tabs-bills',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/PatientTabs/Bills.vue'),
                /* no children */
              },
              {
                path: 'Consultation',
                name: 'patients-components-patient-tabs-consultation',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/PatientTabs/Consultation.vue'),
                /* no children */
              },
              {
                path: 'Control',
                name: 'patients-components-patient-tabs-control',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/PatientTabs/Control.vue'),
                /* no children */
              },
              {
                path: 'Intervention',
                name: 'patients-components-patient-tabs-intervention',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/PatientTabs/Intervention.vue'),
                /* no children */
              },
              {
                path: 'Multimedia',
                name: 'patients-components-patient-tabs-multimedia',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/PatientTabs/Multimedia.vue'),
                /* no children */
              },
              {
                path: 'Timeline',
                name: 'patients-components-patient-tabs-timeline',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/PatientTabs/Timeline.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'Printables',
            /* internal name: 'patients-components-printables' */
            /* no component */
            children: [
              {
                path: 'AdmissionLetter',
                name: 'patients-components-printables-admission-letter',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Printables/AdmissionLetter.vue'),
                /* no children */
              },
              {
                path: 'ConfidentialLetter',
                name: 'patients-components-printables-confidential-letter',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Printables/ConfidentialLetter.vue'),
                /* no children */
              },
              {
                path: 'ConsentForm',
                name: 'patients-components-printables-consent-form',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Printables/ConsentForm.vue'),
                /* no children */
              },
              {
                path: 'DoctorLetter',
                name: 'patients-components-printables-doctor-letter',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Printables/DoctorLetter.vue'),
                /* no children */
              },
              {
                path: 'HospitalReport',
                name: 'patients-components-printables-hospital-report',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Printables/HospitalReport.vue'),
                /* no children */
              },
              {
                path: 'MedicalCertificate',
                name: 'patients-components-printables-medical-certificate',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Printables/MedicalCertificate.vue'),
                /* no children */
              },
              {
                path: 'OperativeReport',
                name: 'patients-components-printables-operative-report',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Printables/OperativeReport.vue'),
                /* no children */
              },
              {
                path: 'PreoperativeAssessment',
                name: 'patients-components-printables-preoperative-assessment',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Printables/PreoperativeAssessment.vue'),
                /* no children */
              },
              {
                path: 'Prescription',
                name: 'patients-components-printables-prescription',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Printables/Prescription.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'PrintingDrawer',
            name: 'patients-components-printing-drawer',
            component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/PrintingDrawer.vue'),
            /* no children */
          },
          {
            path: 'SpeechEhr',
            name: 'patients-components-speech-ehr',
            component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/SpeechEhr.vue'),
            /* no children */
          },
          {
            path: 'Timeline',
            name: 'patients-components-timeline',
            component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Timeline.vue'),
            /* no children */
          },
          {
            path: 'TimelineExpand',
            name: 'patients-components-timeline-expand',
            component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/TimelineExpand.vue'),
            /* no children */
          },
          {
            path: 'Transfert',
            name: 'patients-components-transfert',
            component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Transfert.vue'),
            /* no children */
          },
          {
            path: 'Trash',
            /* internal name: 'patients-components-trash' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: 'cs',
                name: 'patients-components-trash-cs',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Trash/cs.vue'),
                /* no children */
              },
  _definePage_default_96
  ),
  _mergeRouteRecord(
              {
                path: 'gs',
                name: 'patients-components-trash-gs',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Trash/gs.vue'),
                /* no children */
              },
  _definePage_default_97
  ),
  _mergeRouteRecord(
              {
                path: 'ps',
                name: 'patients-components-trash-ps',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/components/Trash/ps.vue'),
                /* no children */
              },
  _definePage_default_98
  )
            ],
          }
        ],
      },
      {
        path: 'consultation',
        /* internal name: 'patients-consultation' */
        /* no component */
        children: [
          {
            path: 'add',
            /* internal name: 'patients-consultation-add' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: 'patients-consultation-add-id',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/consultation/add/[id].vue'),
                /* no children */
              },
  _definePage_default_99
  )
            ],
          },
          {
            path: 'components',
            /* internal name: 'patients-consultation-components' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: 'AddDiagnostic',
                name: 'patients-consultation-components-add-diagnostic',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/consultation/components/AddDiagnostic.vue'),
                /* no children */
              },
  _definePage_default_100
  ),
              {
                path: 'Consultation',
                name: 'patients-consultation-components-consultation',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/consultation/components/Consultation.vue'),
                /* no children */
              },
              {
                path: 'Form',
                name: 'patients-consultation-components-form',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/consultation/components/Form.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'edit',
            /* internal name: 'patients-consultation-edit' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: 'patients-consultation-edit-id',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/consultation/edit/[id].vue'),
                /* no children */
              },
  _definePage_default_101
  )
            ],
          }
        ],
      },
      {
        path: 'control',
        /* internal name: 'patients-control' */
        /* no component */
        children: [
          {
            path: 'add',
            /* internal name: 'patients-control-add' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: 'patients-control-add-id',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/control/add/[id].vue'),
                /* no children */
              },
  _definePage_default_102
  )
            ],
          },
          {
            path: 'components',
            /* internal name: 'patients-control-components' */
            /* no component */
            children: [
              {
                path: 'Control',
                name: 'patients-control-components-control',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/control/components/Control.vue'),
                /* no children */
              },
              {
                path: 'Form',
                name: 'patients-control-components-form',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/control/components/Form.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'edit',
            /* internal name: 'patients-control-edit' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: 'patients-control-edit-id',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/control/edit/[id].vue'),
                /* no children */
              },
  _definePage_default_103
  )
            ],
          }
        ],
      },
      {
        path: 'edit',
        /* internal name: 'patients-edit' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'patients-edit-id',
            component: () => import('/home/medsyn/public_html/resources/js/pages/patients/edit/[id].vue'),
            /* no children */
          },
  _definePage_default_104
  )
        ],
      },
      {
        path: 'intervention',
        /* internal name: 'patients-intervention' */
        /* no component */
        children: [
          {
            path: 'add',
            /* internal name: 'patients-intervention-add' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: 'patients-intervention-add-id',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/intervention/add/[id].vue'),
                /* no children */
              },
  _definePage_default_105
  )
            ],
          },
          {
            path: 'components',
            /* internal name: 'patients-intervention-components' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: 'AddAnesthesiologist',
                name: 'patients-intervention-components-add-anesthesiologist',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/intervention/components/AddAnesthesiologist.vue'),
                /* no children */
              },
  _definePage_default_106
  ),
  _mergeRouteRecord(
              {
                path: 'AddTechnique',
                name: 'patients-intervention-components-add-technique',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/intervention/components/AddTechnique.vue'),
                /* no children */
              },
  _definePage_default_107
  ),
              {
                path: 'Form',
                name: 'patients-intervention-components-form',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/intervention/components/Form.vue'),
                /* no children */
              },
              {
                path: 'Intervention',
                name: 'patients-intervention-components-intervention',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/intervention/components/Intervention.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'edit',
            /* internal name: 'patients-intervention-edit' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: 'patients-intervention-edit-id',
                component: () => import('/home/medsyn/public_html/resources/js/pages/patients/intervention/edit/[id].vue'),
                /* no children */
              },
  _definePage_default_108
  )
            ],
          }
        ],
      },
      {
        path: 'print',
        /* internal name: 'patients-print' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'patients-print-id',
            component: () => import('/home/medsyn/public_html/resources/js/pages/patients/print/[id].vue'),
            /* no children */
          },
  _definePage_default_109
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'trash',
        name: 'patients-trash',
        component: () => import('/home/medsyn/public_html/resources/js/pages/patients/trash.vue'),
        /* no children */
      },
  _definePage_default_110
  )
    ],
  },
  {
    path: '/platform',
    /* internal name: 'platform' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'platform',
        component: () => import('/home/medsyn/public_html/resources/js/pages/platform/index.vue'),
        /* no children */
      },
  _definePage_default_111
  ),
      {
        path: 'components',
        /* internal name: 'platform-components' */
        /* no component */
        children: [
          {
            path: 'Chat',
            /* internal name: 'platform-components-chat' */
            /* no component */
            children: [
              {
                path: 'ChatActiveChatUserProfileSidebarContent',
                name: 'platform-components-chat-chat-active-chat-user-profile-sidebar-content',
                component: () => import('/home/medsyn/public_html/resources/js/pages/platform/components/Chat/ChatActiveChatUserProfileSidebarContent.vue'),
                /* no children */
              },
              {
                path: 'ChatContact',
                name: 'platform-components-chat-chat-contact',
                component: () => import('/home/medsyn/public_html/resources/js/pages/platform/components/Chat/ChatContact.vue'),
                /* no children */
              },
              {
                path: 'ChatLeftSidebarContent',
                name: 'platform-components-chat-chat-left-sidebar-content',
                component: () => import('/home/medsyn/public_html/resources/js/pages/platform/components/Chat/ChatLeftSidebarContent.vue'),
                /* no children */
              },
              {
                path: 'ChatLog',
                name: 'platform-components-chat-chat-log',
                component: () => import('/home/medsyn/public_html/resources/js/pages/platform/components/Chat/ChatLog.vue'),
                /* no children */
              },
              {
                path: 'ChatUserProfileSidebarContent',
                name: 'platform-components-chat-chat-user-profile-sidebar-content',
                component: () => import('/home/medsyn/public_html/resources/js/pages/platform/components/Chat/ChatUserProfileSidebarContent.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'Dialogs',
            /* internal name: 'platform-components-dialogs' */
            /* no component */
            children: [
              {
                path: 'CommentEditDialog',
                name: 'platform-components-dialogs-comment-edit-dialog',
                component: () => import('/home/medsyn/public_html/resources/js/pages/platform/components/Dialogs/CommentEditDialog.vue'),
                /* no children */
              },
              {
                path: 'PostDialog',
                name: 'platform-components-dialogs-post-dialog',
                component: () => import('/home/medsyn/public_html/resources/js/pages/platform/components/Dialogs/PostDialog.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'Feed',
            /* internal name: 'platform-components-feed' */
            /* no component */
            children: [
              {
                path: 'Comment',
                name: 'platform-components-feed-comment',
                component: () => import('/home/medsyn/public_html/resources/js/pages/platform/components/Feed/Comment.vue'),
                /* no children */
              },
              {
                path: 'CreatePost',
                name: 'platform-components-feed-create-post',
                component: () => import('/home/medsyn/public_html/resources/js/pages/platform/components/Feed/CreatePost.vue'),
                /* no children */
              },
              {
                path: 'Post',
                name: 'platform-components-feed-post',
                component: () => import('/home/medsyn/public_html/resources/js/pages/platform/components/Feed/Post.vue'),
                /* no children */
              },
              {
                path: 'Reply',
                name: 'platform-components-feed-reply',
                component: () => import('/home/medsyn/public_html/resources/js/pages/platform/components/Feed/Reply.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'VWindows',
            /* internal name: 'platform-components-vwindows' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: 'Chat',
                name: 'platform-components-vwindows-chat',
                component: () => import('/home/medsyn/public_html/resources/js/pages/platform/components/VWindows/Chat.vue'),
                /* no children */
              },
  _definePage_default_112
  ),
              {
                path: 'Feed',
                name: 'platform-components-vwindows-feed',
                component: () => import('/home/medsyn/public_html/resources/js/pages/platform/components/VWindows/Feed.vue'),
                /* no children */
              },
              {
                path: 'Network',
                name: 'platform-components-vwindows-network',
                component: () => import('/home/medsyn/public_html/resources/js/pages/platform/components/VWindows/Network.vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/register',
    /* internal name: 'register' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'register',
        component: () => import('/home/medsyn/public_html/resources/js/pages/register/index.vue'),
        /* no children */
      },
  _definePage_default_113
  ),
      {
        path: 'components',
        /* internal name: 'register-components' */
        /* no component */
        children: [
          {
            path: 'doctor',
            /* internal name: 'register-components-doctor' */
            /* no component */
            children: [
              {
                path: 'Activation',
                name: 'register-components-doctor-activation',
                component: () => import('/home/medsyn/public_html/resources/js/pages/register/components/doctor/Activation.vue'),
                /* no children */
              },
              {
                path: 'Details',
                name: 'register-components-doctor-details',
                component: () => import('/home/medsyn/public_html/resources/js/pages/register/components/doctor/Details.vue'),
                /* no children */
              },
              {
                path: 'Profile',
                name: 'register-components-doctor-profile',
                component: () => import('/home/medsyn/public_html/resources/js/pages/register/components/doctor/Profile.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'MobileProgressIndicator',
            name: 'register-components-mobile-progress-indicator',
            component: () => import('/home/medsyn/public_html/resources/js/pages/register/components/MobileProgressIndicator.vue'),
            /* no children */
          },
          {
            path: 'patient',
            /* internal name: 'register-components-patient' */
            /* no component */
            children: [
              {
                path: 'Activation',
                name: 'register-components-patient-activation',
                component: () => import('/home/medsyn/public_html/resources/js/pages/register/components/patient/Activation.vue'),
                /* no children */
              },
              {
                path: 'Address',
                name: 'register-components-patient-address',
                component: () => import('/home/medsyn/public_html/resources/js/pages/register/components/patient/Address.vue'),
                /* no children */
              },
              {
                path: 'Details',
                name: 'register-components-patient-details',
                component: () => import('/home/medsyn/public_html/resources/js/pages/register/components/patient/Details.vue'),
                /* no children */
              },
              {
                path: 'Profile',
                name: 'register-components-patient-profile',
                component: () => import('/home/medsyn/public_html/resources/js/pages/register/components/patient/Profile.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
  _mergeRouteRecord(
      {
        path: 'doctor',
        name: 'register-doctor',
        component: () => import('/home/medsyn/public_html/resources/js/pages/register/doctor.vue'),
        /* no children */
      },
  _definePage_default_114
  ),
  _mergeRouteRecord(
      {
        path: 'patient',
        name: 'register-patient',
        component: () => import('/home/medsyn/public_html/resources/js/pages/register/patient.vue'),
        /* no children */
      },
  _definePage_default_115
  )
    ],
  },
  {
    path: '/reset',
    /* internal name: 'reset' */
    /* no component */
    children: [
      {
        path: ':email',
        /* internal name: 'reset-email' */
        /* no component */
        children: [
          {
            path: ':token',
            /* internal name: 'reset-email-token' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'reset-email-token',
                component: () => import('/home/medsyn/public_html/resources/js/pages/reset/[email]/[token]/index.vue'),
                /* no children */
              },
  _definePage_default_116
  )
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/roles',
    /* internal name: 'roles' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'roles',
        component: () => import('/home/medsyn/public_html/resources/js/pages/roles/index.vue'),
        /* no children */
      },
  _definePage_default_117
  ),
      {
        path: 'add',
        /* internal name: 'roles-add' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'roles-add',
            component: () => import('/home/medsyn/public_html/resources/js/pages/roles/add/index.vue'),
            /* no children */
          },
  _definePage_default_118
  )
        ],
      },
      {
        path: 'components',
        /* internal name: 'roles-components' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'Form',
            name: 'roles-components-form',
            component: () => import('/home/medsyn/public_html/resources/js/pages/roles/components/Form.vue'),
            /* no children */
          },
  _definePage_default_119
  )
        ],
      },
      {
        path: 'edit',
        /* internal name: 'roles-edit' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'roles-edit-id',
            component: () => import('/home/medsyn/public_html/resources/js/pages/roles/edit/[id].vue'),
            /* no children */
          },
  _definePage_default_120
  )
        ],
      }
    ],
  },
  {
    path: '/select',
    name: 'select',
    component: () => import('/home/medsyn/public_html/resources/js/pages/select.vue'),
    /* no children */
  },
  {
    path: '/services',
    /* internal name: 'services' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'services',
        component: () => import('/home/medsyn/public_html/resources/js/pages/services/index.vue'),
        /* no children */
      },
  _definePage_default_121
  ),
      {
        path: 'add',
        /* internal name: 'services-add' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'services-add',
            component: () => import('/home/medsyn/public_html/resources/js/pages/services/add/index.vue'),
            /* no children */
          },
  _definePage_default_122
  )
        ],
      },
      {
        path: 'components',
        /* internal name: 'services-components' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'Form',
            name: 'services-components-form',
            component: () => import('/home/medsyn/public_html/resources/js/pages/services/components/Form.vue'),
            /* no children */
          },
  _definePage_default_123
  )
        ],
      },
      {
        path: 'edit',
        /* internal name: 'services-edit' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'services-edit-id',
            component: () => import('/home/medsyn/public_html/resources/js/pages/services/edit/[id].vue'),
            /* no children */
          },
  _definePage_default_124
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'trash',
        name: 'services-trash',
        component: () => import('/home/medsyn/public_html/resources/js/pages/services/trash.vue'),
        /* no children */
      },
  _definePage_default_125
  )
    ],
  },
  {
    path: '/settings',
    /* internal name: 'settings' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':tab',
        name: 'settings-tab',
        component: () => import('/home/medsyn/public_html/resources/js/pages/settings/[tab].vue'),
        /* no children */
      },
  _definePage_default_126
  ),
      {
        path: 'components',
        /* internal name: 'settings-components' */
        /* no component */
        children: [
          {
            path: 'HeaderEditor',
            name: 'settings-components-header-editor',
            component: () => import('/home/medsyn/public_html/resources/js/pages/settings/components/HeaderEditor.vue'),
            /* no children */
          },
          {
            path: 'Menu',
            name: 'settings-components-menu',
            component: () => import('/home/medsyn/public_html/resources/js/pages/settings/components/Menu.vue'),
            /* no children */
          },
          {
            path: 'Printing',
            name: 'settings-components-printing',
            component: () => import('/home/medsyn/public_html/resources/js/pages/settings/components/Printing.vue'),
            /* no children */
          },
          {
            path: 'Shortcut',
            name: 'settings-components-shortcut',
            component: () => import('/home/medsyn/public_html/resources/js/pages/settings/components/Shortcut.vue'),
            /* no children */
          },
          {
            path: 'ShortcutCheckBoxes',
            name: 'settings-components-shortcut-check-boxes',
            component: () => import('/home/medsyn/public_html/resources/js/pages/settings/components/ShortcutCheckBoxes.vue'),
            /* no children */
          },
          {
            path: 'Theme',
            name: 'settings-components-theme',
            component: () => import('/home/medsyn/public_html/resources/js/pages/settings/components/Theme.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/setup',
    /* internal name: 'setup' */
    /* no component */
    children: [
      {
        path: ':email',
        /* internal name: 'setup-email' */
        /* no component */
        children: [
          {
            path: ':token',
            /* internal name: 'setup-email-token' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'setup-email-token',
                component: () => import('/home/medsyn/public_html/resources/js/pages/setup/[email]/[token]/index.vue'),
                /* no children */
              },
  _definePage_default_127
  )
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/statistics',
    /* internal name: 'statistics' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'statistics',
        component: () => import('/home/medsyn/public_html/resources/js/pages/statistics/index.vue'),
        /* no children */
      },
  _definePage_default_128
  ),
      {
        path: 'components',
        /* internal name: 'statistics-components' */
        /* no component */
        children: [
          {
            path: 'Anesthesiologists',
            name: 'statistics-components-anesthesiologists',
            component: () => import('/home/medsyn/public_html/resources/js/pages/statistics/components/Anesthesiologists.vue'),
            /* no children */
          },
          {
            path: 'Balance',
            name: 'statistics-components-balance',
            component: () => import('/home/medsyn/public_html/resources/js/pages/statistics/components/Balance.vue'),
            /* no children */
          },
          {
            path: 'BloodGroups',
            name: 'statistics-components-blood-groups',
            component: () => import('/home/medsyn/public_html/resources/js/pages/statistics/components/BloodGroups.vue'),
            /* no children */
          },
          {
            path: 'Diagnostics',
            name: 'statistics-components-diagnostics',
            component: () => import('/home/medsyn/public_html/resources/js/pages/statistics/components/Diagnostics.vue'),
            /* no children */
          },
          {
            path: 'Genders',
            name: 'statistics-components-genders',
            component: () => import('/home/medsyn/public_html/resources/js/pages/statistics/components/Genders.vue'),
            /* no children */
          },
          {
            path: 'Monthly',
            name: 'statistics-components-monthly',
            component: () => import('/home/medsyn/public_html/resources/js/pages/statistics/components/Monthly.vue'),
            /* no children */
          },
          {
            path: 'Patient',
            name: 'statistics-components-patient',
            component: () => import('/home/medsyn/public_html/resources/js/pages/statistics/components/Patient.vue'),
            /* no children */
          },
          {
            path: 'Prescribers',
            name: 'statistics-components-prescribers',
            component: () => import('/home/medsyn/public_html/resources/js/pages/statistics/components/Prescribers.vue'),
            /* no children */
          },
          {
            path: 'Techniques',
            name: 'statistics-components-techniques',
            component: () => import('/home/medsyn/public_html/resources/js/pages/statistics/components/Techniques.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/techniques',
    /* internal name: 'techniques' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'techniques',
        component: () => import('/home/medsyn/public_html/resources/js/pages/techniques/index.vue'),
        /* no children */
      },
  _definePage_default_129
  ),
      {
        path: 'add',
        /* internal name: 'techniques-add' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'techniques-add',
            component: () => import('/home/medsyn/public_html/resources/js/pages/techniques/add/index.vue'),
            /* no children */
          },
  _definePage_default_130
  )
        ],
      },
      {
        path: 'components',
        /* internal name: 'techniques-components' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'Form',
            name: 'techniques-components-form',
            component: () => import('/home/medsyn/public_html/resources/js/pages/techniques/components/Form.vue'),
            /* no children */
          },
  _definePage_default_131
  )
        ],
      },
      {
        path: 'edit',
        /* internal name: 'techniques-edit' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'techniques-edit-id',
            component: () => import('/home/medsyn/public_html/resources/js/pages/techniques/edit/[id].vue'),
            /* no children */
          },
  _definePage_default_132
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'trash',
        name: 'techniques-trash',
        component: () => import('/home/medsyn/public_html/resources/js/pages/techniques/trash.vue'),
        /* no children */
      },
  _definePage_default_133
  )
    ],
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('/home/medsyn/public_html/resources/js/pages/test.vue'),
    /* no children */
  },
  {
    path: '/users',
    /* internal name: 'users' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'users',
        component: () => import('/home/medsyn/public_html/resources/js/pages/users/index.vue'),
        /* no children */
      },
  _definePage_default_134
  ),
      {
        path: 'add',
        /* internal name: 'users-add' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'users-add',
            component: () => import('/home/medsyn/public_html/resources/js/pages/users/add/index.vue'),
            /* no children */
          },
  _definePage_default_135
  )
        ],
      },
      {
        path: 'components',
        /* internal name: 'users-components' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'Form',
            name: 'users-components-form',
            component: () => import('/home/medsyn/public_html/resources/js/pages/users/components/Form.vue'),
            /* no children */
          },
  _definePage_default_136
  )
        ],
      },
      {
        path: 'edit',
        /* internal name: 'users-edit' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'users-edit-id',
            component: () => import('/home/medsyn/public_html/resources/js/pages/users/edit/[id].vue'),
            /* no children */
          },
  _definePage_default_137
  )
        ],
      }
    ],
  },
  {
    path: '/verify',
    /* internal name: 'verify' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'verify',
        component: () => import('/home/medsyn/public_html/resources/js/pages/verify/index.vue'),
        /* no children */
      },
  _definePage_default_138
  ),
      {
        path: ':email',
        /* internal name: 'verify-email' */
        /* no component */
        children: [
          {
            path: ':token',
            /* internal name: 'verify-email-token' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'verify-email-token',
                component: () => import('/home/medsyn/public_html/resources/js/pages/verify/[email]/[token]/index.vue'),
                /* no children */
              },
  _definePage_default_139
  )
            ],
          }
        ],
      }
    ],
  }
]
