<script setup>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useIntersectionObserver } from '@vueuse/core';
import Navbar from '@/components/front-pages/front-page-navbar.vue';
import Hero from '@/components/front-pages/landing-page/hero.vue';
import Specialties from '@/components/front-pages/landing-page/specialties.vue';
import Features from '@/components/front-pages/landing-page/features.vue';
import Technology from '@/components/front-pages/landing-page/technology.vue';
import Reviews from '@/components/front-pages/landing-page/reviews.vue';
import Pricing from '@/components/front-pages/landing-page/pricing.vue';
import Faq from '@/components/front-pages/landing-page/faq.vue';
import Contact from '@/components/front-pages/landing-page/contact.vue';
import Footer from '@/components/front-pages/front-page-footer.vue';

definePage({
    meta: {
        isRoute: true,
        public: true,
        layout: 'blank'
    }
})

const { locale, t } = useI18n();

onMounted(() => {
    const browserLang = navigator.language.split('-')[0];
    if (['fr', 'ar', 'en'].includes(browserLang)) {
        locale.value = browserLang;
    } else {
        locale.value = 'fr';
    }
    
    document.title = t('seo.title');
    document.querySelector('meta[name="description"]').setAttribute('content', t('seo.description'));
    document.querySelector('meta[property="og:title"]').setAttribute('content', t('seo.title'));
    document.querySelector('meta[property="og:description"]').setAttribute('content', t('seo.description'));
    document.querySelector('meta[name="twitter:title"]').setAttribute('content', t('seo.title'));
    document.querySelector('meta[name="twitter:description"]').setAttribute('content', t('seo.description'));
});

const activeSectionId = ref('');
const refHome = ref();
const refSpecialties = ref();
const refFeatures = ref();
const refTechnology = ref();
const refReviews = ref();
const refPricing = ref();
const refFaq = ref();
const refContact = ref();

useIntersectionObserver([
    refHome,
    refSpecialties,
    refFeatures,
    refTechnology,
    refReviews,
    refPricing,
    refFaq,
    refContact,
], ([{ isIntersecting, target }]) => {
    if (isIntersecting) {
        activeSectionId.value = target.id;
    }
}, { threshold: 0.5 })
</script>

<template>
    <div class="landing-page-wrapper">
        <Navbar :active-id="activeSectionId" />

        <Hero ref="refHome" id="home" />
        <Specialties ref="refSpecialties" id="specialties" />
        <Features ref="refFeatures" id="features" />
        <Technology ref="refTechnology" id="technology" />
        <!-- <Reviews ref="refReviews" id="reviews" /> -->
        <Pricing ref="refPricing" id="pricing" />
        <Faq ref="refFaq" id="faq" />
        <Contact ref="refContact" id="contact" />

        <Footer />
    </div>
</template>

<style lang="scss">
@media (max-width: 960px) and (min-width: 600px) {
    .landing-page-wrapper {
        .v-container {
            padding-inline: 2rem !important;
        }
    }
}
</style>