<script setup>
import { useUser } from "@/composables/Central/useUser";
import { VForm } from 'vuetify/components/VForm'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'
import { onMounted } from "vue";
import authV1BottomShape from '@images/svg/auth-v1-bottom-shape.svg?raw';
import authV1TopShape from '@images/svg/auth-v1-top-shape.svg?raw';
import { requiredValidator,minLengthValidator,confirmedValidator } from '@core/utils/validators.js';
import {ref} from 'vue';
import { useRoute } from "vue-router";

definePage({
  meta: {
    isRoute: true,
    layout: 'blank',
    unauthenticatedOnly: true,
  },
});

const isNewPasswordVisible = ref(false);
const isNewConfirmationPasswordVisible = ref(false);


const { setupForm, userLoading, verifyToken, userProcessing, setupPassword, userSuccess, userErrors, snackBar } = useUser();

const refVForm = ref()

const onSubmit = () => {
  refVForm.value?.validate().then(({ valid: isValid }) => {
    if (isValid) {
      setupPassword();
    }
  });
};

const route = useRoute();

onMounted(() => {
  verifyToken(route.params)
})
</script>

<template>
  <div class="login-page-wrapper">
    <VAppBar>
      <template v-slot:prepend>
        <router-link :to="'/'" class="d-flex align-center">
          <VNodeRenderer :nodes="themeConfig.app.logo" />
          <VToolbarTitle class="text-h4 ml-2">
            <span class="text-capitalize font-weight-bold">{{ themeConfig.app.title }}</span>
          </VToolbarTitle>
        </router-link>
      </template>
    </VAppBar>

    <div class="auth-wrapper d-flex align-center justify-center">
      <VCard width="450" max-width="100%" class="login-card">
        <VCardTitle class="text-center py-4">
          {{ $t('Account Setup') }}
        </VCardTitle>
        <VDivider />
        <VCardText>
          <VForm ref="refVForm" @submit.prevent="onSubmit">
            <VRow>
              <VCol cols="12">
                <AppTextField autocomplete="true" v-model="setupForm.password" :label="$t('Password')"
                  placeholder="············" :rules="[requiredValidator, minLengthValidator(setupForm.password, 8)]"
                  :type="isNewPasswordVisible ? 'text' : 'password'" :error-messages="userErrors.password"
                  :append-inner-icon="isNewPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  @click:append-inner="isNewPasswordVisible = !isNewPasswordVisible" />
              </VCol>
              <VCol cols="12">
                <AppTextField autocomplete="true" v-model="setupForm.password_confirmation"
                  :label="$t('Password Confirmation')" placeholder="············" :rules="[requiredValidator,
                    confirmedValidator(
                      setupForm.password_confirmation,
                      setupForm.password
                    ),
                  ]" :type="isNewConfirmationPasswordVisible ? 'text' : 'password'"
                  :error-messages="userErrors.password_confirmation"
                  :append-inner-icon="isNewConfirmationPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  @click:append-inner="isNewConfirmationPasswordVisible = !isNewConfirmationPasswordVisible" />
              </VCol>
              <VCol cols="12">
                <VBtn v-show="!userSuccess" :loading="userLoading" block type="submit">
                  {{ $t('Join') }}
                  <template #loader>
                    <span class="button-loader">
                      <VIcon size="30" icon="tabler-loader-2" />
                    </span>
                  </template>
                </VBtn>
                <VBtn v-show="userSuccess" color="success" block type="submit">
                  <VIcon icon="tabler-circle-check" size="25" />
                </VBtn>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </div>
  </div>
  <VSnackbar v-model="snackBar.visible" :color="snackBar.color">
    <div class="text-center">{{ $t(snackBar.message) }}.</div>
  </VSnackbar>
</template>

<style lang="scss">
@use "@core-scss/template/pages/page-auth.scss";
</style>
