<script setup>
import { VNodeRenderer } from '@layouts/components/VNodeRenderer';
import { themeConfig } from '@themeConfig';
import { useLogin } from "@/composables/Auth/useLogin";
import { requiredValidator, emailValidator } from '@core/utils/validators.js';
import { ref, onMounted } from 'vue';
import { useInitializationStore } from "@/stores/useInitializationStore";

definePage({
  meta: {
    isRoute: true,
    layout: 'blank',
    unauthenticatedOnly: true,
  },
})

const initializationStore = useInitializationStore();

const isPasswordVisible = ref(false)

const { loginForm, login, loginSuccess, loginLoading, loginErrors } = useLogin();

const refVForm = ref()

const onSubmit = () => {
  refVForm.value?.validate().then(({ valid: isValid }) => {
    if (isValid) login();
  });
};

onMounted(async () => {
  if (initializationStore.isInitialized){
    await initializationStore.reset();
  }
});
</script>

<template>
  <div class="login-page-wrapper">
    <VAppBar class="login-appbar">
      <template v-slot:prepend>
        <router-link :to="'/'" class="d-flex align-center">
          <VNodeRenderer :nodes="themeConfig.app.logo" />
          <VToolbarTitle class="text-h4 ml-2">
            <span class="text-capitalize font-weight-bold">{{ themeConfig.app.title }}</span>
          </VToolbarTitle>
        </router-link>
      </template>
    </VAppBar>

    <div class="auth-wrapper d-flex align-center justify-center">
      <VCard width="450" max-width="100%" class="login-card">
        <VCardTitle class="text-center py-4">
          {{ $t('Login') }}
        </VCardTitle>
        <VDivider />
        <VCardText>
          <VForm ref="refVForm" @submit.prevent="onSubmit">
            <VRow>
              <VCol v-if="loginErrors.login" cols="12">
                <VAlert variant="tonal" color="error">
                  {{ loginErrors.login[0] }}
                </VAlert>
              </VCol>
              <VCol cols="12">
                <AppTextField v-model="loginForm.email" :label="$t('Email')" :placeholder="$t('user') + '@email.com'"
                  type="email" :rules="[requiredValidator, emailValidator]" :error-messages="loginErrors.email" />
              </VCol>
              <VCol cols="12">
                <AppTextField v-model="loginForm.password" :label="$t('Password')" placeholder="············"
                  autocomplete="true" :rules="[requiredValidator]" :type="isPasswordVisible ? 'text' : 'password'"
                  :error-messages="loginErrors.password"
                  :append-inner-icon="isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  @click:append-inner="isPasswordVisible = !isPasswordVisible" />
                <VRow class="mt-2 mb-4 align-center justify-center">
                  <VCol cols="12" sm="6" class="d-flex align-center justify-center">
                    <VCheckbox v-model="loginForm.rememberMe" :label="$t('Remember me')" density="compact" hide-details
                      class="label-spacing" />
                  </VCol>
                  <VCol cols="12" sm="6" class="d-flex justify-center">
                    <RouterLink class="text-primary" :to="{ name: 'forgot-password' }">
                      {{ $t('Forgot Password') }}?
                    </RouterLink>
                  </VCol>
                </VRow>

                <VBtn v-show="!loginSuccess" :loading="loginLoading" block type="submit">
                  {{ $t('Login') }}
                  <template #loader>
                    <span class="button-loader">
                      <VIcon size="30" icon="tabler-loader-2" />
                    </span>
                  </template>
                </VBtn>
                <VBtn v-show="loginSuccess" color="success" block type="submit">
                  <VIcon icon="tabler-circle-check" size="25" />
                </VBtn>
                <VBtn :to="{ name: 'register' }" class="mt-5" color="secondary"
                  v-bind="{ variant: $vuetify.theme.name === 'dark' ? 'outlined' : undefined }" block>
                  {{ $t('Register') }}
                </VBtn>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </div>
  </div>
</template>

<style lang="scss">
@use "@core-scss/template/pages/page-auth.scss";

.login-page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.login-appbar {
  flex-shrink: 0;
}

.auth-wrapper {
  flex-grow: 1;
  padding: 1rem;
}

.login-card {
  width: 450px;
}

@media (max-width: 599.98px) {
  .login-card {
    width: 100% !important;
  }

  .v-col {
    padding: 6px 12px !important;
  }
}
</style>