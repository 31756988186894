<script setup>
import { usePatient } from "@/composables/Tenant/usePatient";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import RestoreConfirmation from "@/components/RestoreConfirmation.vue";
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { ref, watchEffect, computed } from "vue";
import {useAccessControl} from '@/composables/useAccessControl';

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "patients-trash",
        userTypes: ['doctor', 'user'],
        permission: 'read_patient',
    },
});

const vuetifyDisplays = useDisplay();
const { canAccess } = useAccessControl();
const { t } = useI18n();
const patients = ref([]);
const totalPatients = ref(0);
const searchQuery = ref("");
const itemsPerPage = ref(10);
const page = ref(1);
const sortBy = ref('id');
const orderBy = ref('desc');

const updateOptions = (options) => {
    fetchingPatients.value = true;
    page.value = options.page;
    sortBy.value = options.sortBy[0]?.key;
    orderBy.value = options.sortBy[0]?.order;
};

const { fetchPatients, fetchingPatients, destroyPatient, recoverPatient } = usePatient();

const refreshPatients = () => {
    fetchPatients({
        query: {
            q: searchQuery.value,
            itemsPerPage: itemsPerPage.value,
            page: page.value,
            sortBy: sortBy.value,
            orderBy: orderBy.value,
            isDeleted: true,
        },
    })
        .then((response) => {
            fetchingPatients.value = false;
            patients.value = response.data.patients;
            totalPatients.value = response.data.totalPatients;
        })
        .catch((error) => {
            console.error(error);
        });
};

watchEffect(refreshPatients);

const headers = computed(() => {
    let baseHeaders = [];

    const mdLgHeaders = [
        { title: t("First Name"), value: "first_name", sortable: false, order: 2 },
        { title: t("Last Name"), value: "last_name", sortable: false, order: 3 },
        { title: t("Age"), value: "age", order: 4 },
        { title: t("Gender"), value: "gender", sortable: false, order: 5 },
    ];

    const smAndUpHeaders = [
        { title: "Nº", value: "pid", order: 1 },
    ];

    const smHeaders = [
        { title: t("Full Name"), value: "full_name", sortable: false, order: 6 },
    ];

    if (vuetifyDisplays.smAndUp.value) {
        baseHeaders = [...baseHeaders, ...smAndUpHeaders];
    }

    if (vuetifyDisplays.mdAndUp.value) {
        baseHeaders.push(...mdLgHeaders);
    }

    if (vuetifyDisplays.smAndDown.value) {
        baseHeaders.push(...smHeaders);
    }

    const actionsHeader = { title: t("Actions"), value: "actions", sortable: false, align: "end", order: 8 };

    if (canAccess.byPermission('read_patient') || canAccess.byPermission('write_patient') || canAccess.byPermission('delete_patient')) {
        baseHeaders.push(actionsHeader);
    }

    baseHeaders.sort((a, b) => a.order - b.order);

    return baseHeaders;
});

const deletePatient = async (id) => {
    await destroyPatient(id).then(() => {
        refreshPatients();
        hideDeleteDialog();
    });
};

const restorePatient = async (id) => {
    await recoverPatient(id).then(() => {
        refreshPatients();
        hideRestoreDialog();
    });
};

const selectedId = ref(null);

const isDeleteDialogVisible = ref(false);
const deleteMessage = computed(() => {
    const patient = patients.value.find(p => p.id === selectedId.value);
    return patient ? t('deletePatientMessage', { name: patient.fullname }) : '';
});

const showDeleteDialog = (id) => {
    selectedId.value = id;
    isDeleteDialogVisible.value = true;
}

const hideDeleteDialog = () => {
    isDeleteDialogVisible.value = false;
}

const isRestoreDialogVisible = ref(false);
const restoreMessage = computed(() => {
    const patient = patients.value.find(p => p.id === selectedId.value);
    return patient ? t('restorePatientMessage', { name: patient.fullname }) : '';
});

const showRestoreDialog = (id) => {
    selectedId.value = id;
    isRestoreDialogVisible.value = true;
}

const hideRestoreDialog = () => {
    isRestoreDialogVisible.value = false;
}

</script>

<template>
    <section>
        <VRow>
            <VCol cols="12">
                <VCard :title="$t('Deleted Patient List')">
                    <VDivider />

                    <VCardText class="d-flex flex-wrap py-4 gap-4">

                        <div class="me-3 d-none d-sm-flex gap-3">
                            <AppSelect :model-value="itemsPerPage" :items="[
                                { value: 10, title: '10' },
                                { value: 25, title: '25' },
                                { value: 50, title: '50' },
                                { value: 100, title: '100' },
                                { value: -1, title: 'All' },
                            ]" style="inline-size: 6.25rem" @update:model-value="itemsPerPage = parseInt($event, 10)" />
                        </div>

                        <VSpacer />

                        <div class="d-flex align-center flex-wrap gap-4">
                            <div style="inline-size: 10rem">
                                <AppTextField v-model="searchQuery" :placeholder="$t('Search')" density="compact" />
                            </div>

                        </div>
                    </VCardText>

                    <VDivider />

                    <VDataTableServer v-model:items-per-page="itemsPerPage" loading-text="Loading"
                        :loading="fetchingPatients" v-model:page="page" :items="patients" :items-length="totalPatients"
                        :headers="headers" class="text-no-wrap" @update:options="updateOptions">
                        <template #item.pid="{ item }">
                            <span class="text-capitalize font-weight-medium">{{ item.pid }}</span>
                        </template>

                        <template #item.first_name="{ item }">
                            <div class="d-flex align-center gap-4">
                                <span>{{ item.first_name || '-' }}</span>
                            </div>
                        </template>

                        <template #item.last_name="{ item }">
                            <div class="d-flex align-center gap-4">
                                <span>{{ item.last_name || '-' }}</span>
                            </div>
                        </template>

                        <template #item.full_name="{ item }">
                            <div class="d-flex align-center gap-4">
                                <span>{{ (item.first_name || '') + ' ' + (item.last_name || '') }}</span>
                            </div>
                        </template>

                        <template #item.age="{ item }">
                            <span>{{ (item.date_of_birth) ? (item.agey + ' Ans ' + item.agem + ' Mois') : '-' }}</span>
                        </template>

                        <template #item.gender="{ item }">
                            <span>{{ item.gender || '-' }}</span>
                        </template>

                        <template #item.actions="{ item }">
                            <div class="align-end">
                                <IconBtn v-if="canAccess.byPermission('write_patient')" @click="showRestoreDialog(item.id)">
                                    <VIcon icon="tabler-restore" />
                                </IconBtn>

                                <IconBtn v-if="canAccess.byPermission('delete_patient')" @click="showDeleteDialog(item.id)">
                                    <VIcon icon="tabler-trash" />
                                </IconBtn>
                            </div>
                        </template>
                    </VDataTableServer>
                </VCard>
            </VCol>
        </VRow>
    </section>
    <DeleteConfirmation :is-dialog-visible="isDeleteDialogVisible" :id="selectedId" :message="deleteMessage"
        @delete="deletePatient($event)" @cancel="hideDeleteDialog" />

    <RestoreConfirmation :is-dialog-visible="isRestoreDialogVisible" :id="selectedId" :message="restoreMessage"
        @restore="restorePatient($event)" @cancel="hideRestoreDialog" />
</template>

<style lang="scss">
.text-capitalize {
    text-transform: capitalize;
}

.patient-list-name:not(:hover) {
    color: rgba(var(--v-theme-on-background), var(--v-medium-emphasis-opacity));
}

// .align-end,
// .v-data-table__th:last-child {
//     display: flex !important;
//     justify-content: flex-end !important;
// }</style>
